<template>
    <b-container fluid class="pa-0 pt-2">
      <v-expansion-panels accordion :style="{width:'925px'}">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Ubah kata sandi
            <template v-slot:actions v-if="$store.getters.dataUser !== null ? !$store.getters.dataUser.changePass : true">
              <v-icon color="warning">
                mdi-alert-circle
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-n1 ml-n2 mr-n2">
            <template>
                <b-row class="justify-content-center mt-2" v-if="$store.getters.dataUser !== null ? !$store.getters.dataUser.changePass : ''">
                      <v-alert type="warning" width="925" dismissible>
                        Anda masih menggunakan kata sandi default, demi keamanan disarankan untuk ganti kata sandi
                      </v-alert>
                </b-row>
                <b-row class="justify-content-center row mt-2">
                    <b-card class="card card-form">
                    <h1 :class="`text-subtitle-1 font-weight-light`" style="text-align:right" @click="showPass = !showPass">{{showPass ? 'sembunyikan':'tampilkan'}}</h1>
                      <v-text-field
                          outlined
                          label="Ketik kata sandi saat ini"
                          :type="showPass ? 'text':'password'"
                          v-model="form.currentPassword"
                      ></v-text-field>
                      <v-text-field
                          outlined
                          label="Ketik kata sandi baru"
                          :type="showPass ? 'text':'password'"
                          v-model="form.newPassword"
                      ></v-text-field>
                      <v-text-field
                          outlined
                          label="Ketik ulang kata sandi baru"
                          :type="showPass ? 'text':'password'"
                          v-model="form.confirmNewPassword"
                      ></v-text-field>
                      <h1 :class="`text-caption font-weight-light mt-4`" v-if="message !== null" style="color:red">{{message}}</h1>
                      
                    </b-card>
                </b-row>
                <b-row>
                    <v-btn type="submit" color="primary" width="200" :block=block @click="changePassword()">
                      <span v-show="!isLoad">Ubah kata sandi</span>
                      <b-spinner v-show="isLoad" small></b-spinner>
                    </v-btn>
                </b-row>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Alamat email
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-n1 ml-n2 mr-n2">
            <b-row class="justify-content-center row mt-2">
                <b-card class="card card-form">
                  <v-text-field
                      label="Alamat email yang digunakan"
                      type="email"
                      v-model="form.email"
                  ></v-text-field>
                  <h1 :class="`text-caption font-weight-light mt-4`" v-if="messageEmail !== null" style="color:red">{{messageEmail}}</h1>
                </b-card>
            </b-row>
            <b-row>
                <v-btn type="submit" class="mr-1" align="left" color="primary" width="150" @click="changeEmail()" :disabled=!isChange>
                  <span v-show="!isLoad">Ubah email</span>
                  <b-spinner v-show="isLoad" small></b-spinner>
                </v-btn>
                <v-btn type="submit" align="left" color="error" width="150" @click="resetEmail()" :disabled=!isChange>
                  Batal
                </v-btn>
            </b-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row v-if="this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'">
        <v-col cols="12" align="left">
          <v-btn
            text
            color="blue-grey lighten-2"
            @click="$root.logout"
          >
          <v-icon
            left
          >
            mdi-logout
          </v-icon>
            Keluar
          </v-btn>
        </v-col>
      </v-row>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          absolute
        >
          {{snackbarMsg}}
        </v-snackbar>
    </b-container>
</template>

<script>
export default {
  name: 'Security',
  props: ['email', 'block'],
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        email: this.email.data.email,
        resetEmail: this.email.data.email
      },
      isChange: false,
      message: null,
      messageEmail: null,
      isLoad: false,
      timeout: 3000,
      snackbar: false,
      panel: 0,
      snackbarMsg: '',
      showPass: false
    }
  },
  watch: {
    'form.currentPassword': function() {
      this.message = null
    },
    'form.confirmNewPassword': function() {
      this.message = null
    },
    'form.newPassword': function() {
      this.message = null
    },
    'form.email': function() {
      this.messageEmail = null
      if(this.form.email !== this.form.resetEmail) this.isChange = true
    },
    email: function(newVal){
      this.form.email = newVal.data.email
    }
  },
  methods: {
    reset(){
      this.form.currentPassword = ''
      this.form.newPassword = ''
      this.form.confirmNewPassword = ''
    },
    resetEmail(){
      this.form.email = this.form.resetEmail
      this.isChange = false
    },
    async changeEmail(){
      var success = false
      this.isLoad = true
      if(this.form.email === ''){
        this.messageEmail = 'Form email tidak boleh kosong'
        this.isLoad = false
        return
      }

      await this.$root.api.sentAPI(
      {
          method: 'post',
          api: this.$root.api.post.changeEmail,
          form: {
              email: this.form.email
          }
      }).then((res) => {
          // console.log(JSON.stringify(res))
          if(res.status.status === 'Success'){
            success = true
            this.snackbarMsg = res.status.message + '. Silahkan login kembali'
            this.snackbar = true
            this.isChange = false
            this.$store.commit('changeEmail', true)
          }else{
            this.messageEmail = res.status.message
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(success){
        await this.$store.dispatch('LogOut')
        this.$router.push('/login')
      }
      this.isLoad = false
    },
    async changePassword(){
      this.isLoad = true
      if(this.form.currentPassword === '' || this.form.newPassword === ''){
        this.message = 'Harap isi form dahulu'
        this.isLoad = false
        return
      }
      
      if(this.form.newPassword !== this.form.confirmNewPassword){
        this.message = 'Kata sandi tidak sama'
        this.isLoad = false
        return
      }

      await this.$root.api.sentAPI(
      {
          method: 'post',
          api: this.$root.api.post.changePassword,
          form: {
              currentPassword: this.form.currentPassword,
              newPassword: this.form.newPassword
          }
      }).then((res) => {
          // console.log(JSON.stringify(res))
          if(res.status.status === 'Success'){
            this.snackbarMsg = res.status.message
            this.snackbar = true
            this.reset()
            if(!this.$store.getters.dataUser.changePass){
              this.$store.commit('setStatusChangePass', true)
            }
          }else{
            this.message = res.status.message
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      this.isLoad = false
    }
  },
  created(){
    this.form.email = this.email.data.email
  },
  updated() {
    this.$emit('onChange', this.isChange)
  }
}
</script>
<style scoped>
.row{
  margin-bottom: 10px;
}
.card {
  width: 925px;
  text-align: left;
}
.card-form{
  min-height: 100px;
}
.v-application .text-subtitle-1{
  font-size: 12px !important;
  cursor: pointer;
}
</style>
