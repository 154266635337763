<template>
    <!-- nav for admin -->
    <v-card
        flat
        tile>
        <v-app-bar clipped-left flat app color="white" v-if="role == $root.role.admin && !showBottomNav">
          <v-app-bar-nav-icon @click.stop="isClicked"></v-app-bar-nav-icon>

          <v-toolbar-title><img alt="Bufu logo" src="../../assets/bufu-logo-load.png" width="150"></v-toolbar-title>

          <v-spacer />
          <span :style="{marginRight:'20px'}">malik chaudhary nurhadhiat ahmad</span>
          <v-avatar color="primary" size="40">
            <span class="white--text headline">M</span>
          </v-avatar>
        </v-app-bar>

        <!-- nav for user -->
        <b-navbar toggleable="lg" variant="white" :fixed="offsetTop >= 56 ? 'top':''" :style="offsetTop >= 56 ? {height:'56px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}:{height:'56px'}" v-if="role == $root.role.member && !showBottomNav">
          <b-navbar-brand style="margin-left:100px" to="/"><img alt="Bufu logo" src="../../assets/bufu-logo-load.png" width="150"></b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav style="margin-left:50px">
              <template v-for="menu in menus" >
                  <NavMenu :menu=menu :key=menu.link />
              </template>
            </b-navbar-nav>
            
            <b-navbar-nav class="ml-auto">
              <b-nav-item><code style="font-size:15px">ANGGOTA {{data.memberStatus.toUpperCase()}}</code></b-nav-item>
              <v-menu
                bottom
                min-width="250"
                :offset-y="offset"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar 
                    color="primary" 
                    size="35"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span v-if="data.avatar === null" class="white--text body-1">{{$root.firstChart($store.getters.dataUser !== null ? $store.getters.dataUser.name : '')}}</span>
                    <img
                      v-else
                      alt="Avatar"
                      :src="'data:image/jpg;base64,'+data.avatar"
                    >
                  </v-avatar>
                </template>

                <v-card>
                  <v-list class="mb-n5">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar 
                          color="primary" 
                          size="40"
                        >
                          <span v-if="data.avatar === null" class="white--text body-1">{{$root.firstChart($store.getters.dataUser !== null ? $store.getters.dataUser.name : '')}}</span>
                          <img
                            v-else
                            alt="Avatar"
                            :src="'data:image/jpg;base64,'+data.avatar"
                          >
                        </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title :style="{textAlign:'left'}" v-text="$store.getters.dataUser !== null ? $store.getters.dataUser.name : ''"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                        <v-btn
                          class="mr-1"
                          text
                          color="indigo"
                          to="/profile"
                          :style="{textDecoration:'none'}"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-account-circle-outline
                          </v-icon>
                          Lihat profil
                        </v-btn>
                        <v-btn
                          text
                          color="red"
                          @click="$root.logout"
                          :style="{textDecoration:'none'}"
                        >
                        <v-icon
                          left
                          dark
                        >
                          mdi-logout
                        </v-icon>
                          Keluar
                        </v-btn>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <v-app-bar clipped-left flat app color="white" v-if="showBottomNav">
          <v-spacer />
          <v-toolbar-title><img alt="Bufu logo" src="../../assets/bufu-logo-load.png" width="150"></v-toolbar-title>
          <v-spacer />
        </v-app-bar>
      </v-card>
</template>
<script>
import listmenu from '@/lib/json/list-menu.json'

import NavMenu from './NavMenu.vue'


export default {
    name: 'Appbar',
    props: ['drawer', 'showBottomNav', 'role'],
    components: {
      NavMenu,
    },
    data() {
      return {
        offset: true,
        offsetTop: 0,
        clicked: this.drawer,
        menus: listmenu.data,
        data: {
          memberStatus: this.$store.getters.dataUser !== null ? this.$store.getters.dataUser.statusMember === 'Active' ? 'Aktif' : 'Tidak Aktif' : 'Tidak Aktif',
          avatar: null
        }
      }
    },
    methods: {
      isClicked() {
        this.clicked = !this.drawer
        this.$emit('clicked', this.clicked)
      },
      getAvatar(){
        if(this.$store.getters.getAvatar){
          // console.log(this.$store.getters.getAvatar)
          this.$root.api.sentAPI(
          {
            method: 'get',
            api: this.$root.api.get.avatar
          }).then(res => {
              this.data.avatar = res.avatar
          })
          .catch((err) => {
              this.$root.isError = true
              if(!navigator.onLine) this.$root.isError = err.message
          })
        }
      }
    },
    mounted(){
      this.getAvatar()
      this.data.memberStatus = this.$store.getters.dataUser !== null ? this.$store.getters.dataUser.statusMember === 'Active' ? 'Aktif' : 'Tidak Aktif' : 'Tidak Aktif'
      window.document.onscroll = () => {
        this.offsetTop = window.scrollY
      }
    },
    updated(){
      this.getAvatar()
      this.data.memberStatus = this.$store.getters.dataUser !== null ? this.$store.getters.dataUser.statusMember === 'Active' ? 'Aktif' : 'Tidak Aktif' : 'Tidak Aktif'
    }
}
</script>
<style lang="css" scoped>
.v-menu__content.theme--light.menuable__content__active{
  top: 64px !important
}
</style>