<template>
  <div>
      <v-main>
        <v-container fluid>
          <v-row class="justify-content-left row ml-1">
              <h3>Hai, {{$store.getters.dataUser !== null ? $store.getters.dataUser.name.split(' ')[0] : ''}}!</h3>
          </v-row>
          <v-row class="justify-content-left row ml-1">
            <p style="text-align:left">Berikut ini adalah simpanan yang telah anda simpan di KBUFU</p>
          </v-row>
          
          <v-row class="justify-content-center" dense>
            <template v-for="(balance, i) in listBalance">
                <v-col cols="12" xl="3" md="3" sm="12" xs="12" :key="i">
                    <v-alert
                    elevation="1"
                    style="text-align:left;"
                    >
                      <div class="subtitle-1" >
                        {{$root.capitalFirstChart(balance.simpananName)}}
                      </div>
                      <span class="caption">IDR </span>
                      <span class="caption">{{$root.currencyFormat(balance.simpananAmount)}}</span>
                    </v-alert>
                </v-col>
            </template>
          </v-row>
          <v-divider />
          <!-- soon e-commerce -->
          <!-- <v-row class="justify-content-center">
            <v-col md="2">
              <V-card>
                <span>PULSA</span>
              </V-card>
            </v-col>
            <v-col md="2">
              <V-card>
                <span>PULSA</span>
              </V-card>
            </v-col>
            <v-col md="2">
              <V-card>
                <span>PULSA</span>
              </V-card>
            </v-col>
            <v-col md="2">
              <V-card>
                <span>PULSA</span>
              </V-card>
            </v-col>
          </v-row> -->
          <template>

          </template>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  name: 'Home',
  components: {
    OverlayProgress
  },
  data() {
    return {
      listBalance: [],
      color: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
    }
  },
  methods:{
    generatorColor(){
      return this.color = '#'+(Math.random()*0xFFFFFF<<0).toString(16)
    }
  },
  async mounted() {
    await this.$root.api.sentAPI(
    {
      method: 'get',
      api: this.$root.api.get.saldo
    }).then(res => {
      for (let i = 0; i < res.data.length; i++) {
        this.listBalance.push({
          simpananName: res.data[i].simpananName,
          simpananAmount: res.data[i].simpananAmount,
          color: this.generatorColor()
        })
      }
    })
    .catch((err) => {
        this.$root.isError = true
        if(!navigator.onLine) this.$root.isError = err.message
    })
    .finally(() => {
      if(!this.$root.isError) this.$root.overlay = false
    })
    // this.$nextTick(function () {this.$root.overlay = false})
  },
}
</script>
<style scoped>
sup{
  font-size: 16px
}
span{
  font-size: 24px
}
</style>
