<template>
    <b-container fluid class="pa-0 pt-2">
      <b-row class="justify-content-center row">
          <b-card>
              <v-row class="justify-content-center row">
                  <v-col cols="12" align="center">
                    <v-avatar 
                      color="primary" 
                      size="200"
                    >
                      <span v-if="form.avatar === null || form.avatar === undefined" class="white--text h1">{{$root.firstChart($store.getters.dataUser !== null ? $store.getters.dataUser.name : '')}}</span>
                      <img
                        v-else
                        :src="form.newAvatar !== null ? form.newAvatar : 'data:image/jpg;base64,'+form.avatar"
                      >
                    </v-avatar>   
                    </v-col>
                    <v-col align="center">
                        <v-btn color="primary" @click="$refs.file.click()">Ganti Foto</v-btn>
                        <input type="file" ref="file" style="display: none" accept="image/*" @change="onFileSelect">
                        <p class="mt-1" v-if="limitSize"><code>Ukuran foto maksimal 1 MB</code></p>
                    </v-col>
              </v-row>
          </b-card>
      </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Nama Sesuai Identitas" class="card card-form">
            <v-text-field
              class="input"
              label="Nama Anggota Koperasi"
              v-model="form.fullName"
              :disabled=false
            ></v-text-field>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Nomor Anggota" class="card card-form">
            <v-text-field
              class="input"
              label="Nomor Anggota Koperasi"
              v-model="form.memberNo"
              disabled
            ></v-text-field>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Nomor Identitas" class="card card-form">
            <v-text-field
              class="input"
              :label="form.label"
              v-model="form.nomorID"
              :maxlength="maxLengthID"
              :disabled=false
            ></v-text-field>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Nomor Handphone / Telp" class="card card-form">
            <v-text-field
              class="input"
              label="Nomor aktif"
              :error-messages="phoneError"
              v-model="form.phoneNo"
              required
              type="number"
              @input="$v.phoneNo.$touch()"
              @blur="$v.phoneNo.$touch()"
            ></v-text-field>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Tempat / Tgl lahir" class="card card-form">
            <b-row>
              <b-col md="3">
                  <v-combobox
                    v-model="form.pob"
                    :items="form.listCityAll"
                    :error-messages="pobError"
                    item-text="name"
                    item-value="code"
                    label="Tempat Lahir"
                    @input="$v.pob.$touch()"
                    @blur="$v.pob.$touch()"
                    required
                  ></v-combobox>
                  <!-- to get value combobox, get form.pob.name / code -->
              </b-col>
              <b-col md="9">
                  <v-text-field
                    label="Tanggal Lahir"
                    :error-messages="dobError"
                    v-model="form.dob"
                    required
                    type="date"
                    @input="$v.dob.$touch()"
                    @blur="$v.dob.$touch()"
                  ></v-text-field>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
          <b-card title="Jenis Kelamin" class="card card-form">
            <v-radio-group
              class="input"
              required
              :error-messages="genderError"
              v-model="form.gender"
              row
            >
              <v-radio
                label="Laki-Laki"
                value="L"
              ></v-radio>
              <v-radio
                label="Perempuan"
                value="P"
              ></v-radio>
            </v-radio-group>
          </b-card>
        </b-row>
        <b-row class="justify-content-center row">
            <b-card title="Alamat Sesuai Identitas" class="card card-form">
              <b-row>
                <v-col md="1">
                    <v-text-field
                      label="RT"
                      v-model="form.addressByID.rt"
                      type="text"
                    ></v-text-field>
                </v-col>
                <v-col md="1">
                    <v-text-field
                      label="RW"
                      v-model="form.addressByID.rw"
                      type="text"
                    ></v-text-field>
                </v-col>
                <b-col md="3">
                  <v-combobox
                    v-model="form.addressByID.province"
                    :items="listProvince"
                    :error-messages="addressProvinceError"
                    required
                    item-text="name"
                    item-value="code"
                    label="Provinsi *"
                    @input="$v.addressByID.province.$touch()"
                    @blur="$v.addressByID.province.$touch()"
                  ></v-combobox>
              </b-col>
              <b-col md="3">
                  <v-combobox
                    v-model="form.addressByID.city"
                    :items="form.addressByID.listCity"
                    :error-messages="addressCityError"
                    required
                    item-text="name"
                    item-value="code"
                    label="Kab/Kota *"
                    @input="$v.addressByID.city.$touch()"
                    @blur="$v.addressByID.city.$touch()"
                  ></v-combobox>
              </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByID.district"
                      :items="form.addressByID.listDistrict"
                      :error-messages="addressDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kecamatan *"
                      @input="$v.addressByID.district.$touch()"
                      @blur="$v.addressByID.district.$touch()"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByID.subDistrict"
                      :items="form.addressByID.listSubDistrict"
                      :error-messages="addressSubDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kelurahan *"
                      @input="$v.addressByID.subDistrict.$touch()"
                      @blur="$v.addressByID.subDistrict.$touch()"
                    ></v-combobox>
                </b-col>
                <b-col md="9">
                    <v-text-field
                      label="Masukan Alamat"
                      v-model="form.addressByID.address"
                      maxlength="60"
                      type="text"
                    ></v-text-field>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
        <b-row class="justify-content-center row">
            <b-card title="Alamat Sesuai Domisili " class="card card-form">
              <b-row>
                <b-col md="12">
                  <b-form-checkbox
                    id="cb-address"
                    v-model="form.cbAddress"
                    name="cb-address"
                    value="1"
                    unchecked-value="0"
                    @change="checkFormAddress()"
                  >
                    Alamat sama dengan identitas
                  </b-form-checkbox>
                </b-col>
                <v-col md="1">
                    <v-text-field
                      label="RT"
                      v-model="form.addressByDomicile.rt"
                      type="text"
                      :disabled="form.cbAddress==1"
                    ></v-text-field>
                </v-col>
                <v-col md="1">
                    <v-text-field
                      label="RW"
                      v-model="form.addressByDomicile.rw"
                      type="text"
                      :disabled="form.cbAddress==1"
                    ></v-text-field>
                </v-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.province"
                      :items="listProvince"
                      :error-messages="addressDomicileProvinceError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Provinsi *"
                      @input="$v.addressByDomicile.province.$touch()"
                      @blur="$v.addressByDomicile.province.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.city"
                      :items="form.addressByDomicile.listCity"
                      :error-messages="addressDomicileCityError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kab/Kota *"
                      @input="$v.addressByDomicile.city.$touch()"
                      @blur="$v.addressByDomicile.city.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.district"
                      :items="form.addressByDomicile.listDistrict"
                      :error-messages="addressDomicileDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kecamatan *"
                      @input="$v.addressByDomicile.district.$touch()"
                      @blur="$v.addressByDomicile.district.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.subDistrict"
                      :items="form.addressByDomicile.listSubDistrict"
                      :error-messages="addressDomicileSubDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kelurahan *"
                      @input="$v.addressByDomicile.subDistrict.$touch()"
                      @blur="$v.addressByDomicile.subDistrict.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="9">
                    <v-text-field
                      label="Masukan Alamat"
                      v-model="form.addressByDomicile.address"
                      type="text"
                      maxlength="60"
                      :disabled="form.cbAddress==1"
                    ></v-text-field>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <Alert 
              border="left" 
              color="red lighten-2" 
              :show=validate.showMsg 
              message="Terdapat data yang belum sesuai, mohon periksa kembali formulir" />
        <b-row>
          <v-btn type="submit" color="primary" width="200" :block=block @click="updateData()">
            <span v-show="!isLoad">Perbarui</span>
            <b-spinner v-show="isLoad" small></b-spinner>
          </v-btn>
        </b-row>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          absolute
        >
          {{message}}
        </v-snackbar>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
    </b-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Alert from '@/components/Alert.vue'
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  name: 'Account',
  props: ['accountData','block'],
  mixins: [validationMixin],
  validations: {
      phoneNo: {required},
      pob: {required},
      dob: {required},
      gender: {required},
      addressByID: {
        province: {required},
        city: {required},
        subDistrict: {required},
        district: {required}
      },
      addressByDomicile: {
        province: {required},
        city: {required},
        subDistrict: {required},
        district: {required}
      },
    },
  components:{Alert,OverlayProgress},
  data() {
    return {
      validate: {
        showMsg: false,
        phoneNo: false,
        pob: false,
        dob: false,
        gender: false,
        addressByID: {
          province: false,
          city: false,
          subDistrict: false,
          district: false
        },
        addressByDomicile: {
          province: false,
          city: false,
          subDistrict: false,
          district: false
        },
      },
      form: {
        memberNo: '',
        branch: '',
        fullName: '',
        tanzeem: '',
        avatarFile: '',
        newAvatar: null,
        nomorID: '',
        phoneNo: '',
        pob: '',
        dob: '',
        gender: '',
        listCityAll: [],
        addressByID: {
          rt: '',
          rw: '',
          province: '',
          city: '',
          subDistrict: '',
          district: '',
          address: '',
          listCity: [],
          listSubDistrict: [],
          listDistrict: [],
        },
        addressByDomicile: {
          rt: '',
          rw: '',
          province: '',
          city: '',
          subDistrict: '',
          district: '',
          address: '',
          listCity: [],
          listSubDistrict: [],
          listDistrict: [],
        },
        cbAddress: '0',
        labelID: 'KTP/SIM/PASSPORT',
      },
      listProvince: [],
      isChange: false,
      isLoad: false,
      snackbar: false,
      timeout: 3000,
      message: 'Data berhasil diperbaharui',
      limitSize: false,
      maxLengthID: 16
    }
  },
  computed: {
    phoneError() {
      const errors = []
        if (!this.$v.phoneNo.$dirty) return errors
        if(this.form.phoneNo.length==0) errors.push('Mohon masukan nomor handphone/telp anda.')
        return errors
    },
    pobError() {
      const errors = []
        if (!this.$v.pob.$dirty) return errors
        if(typeof this.form.pob !== typeof {} && this.form.pob.length>0) errors.push('Mohon masukan tempat sesuai pada pilihan.')
        if(this.form.pob.length==0) errors.push('Mohon masukan tempat lahir anda.')
        return errors
    },
    dobError() {
      const errors = []
        if (!this.$v.dob.$dirty) return errors
        if(this.form.dob.length==0) errors.push('Mohon masukan tanggal lahir anda.')
        return errors
    },
    genderError() {
      const errors = []
        if (!this.$v.gender.$dirty) return errors
        if(this.form.gender.length==0) errors.push('Mohon masukan jenis kelamin anda.')
        return errors
    },
    addressProvinceError() {
      const errors = []
        if (!this.$v.addressByID.province.$dirty) return errors
        if(typeof this.form.addressByID.province !== typeof {} && this.form.addressByID.province.length>0) 
          errors.push('Mohon masukan provinsi sesuai pada pilihan.')
        if(this.form.addressByID.province.length==0) errors.push('Mohon masukan alamat provinsi anda.')
        return errors
    },
    addressCityError() {
      const errors = []
        if (!this.$v.addressByID.city.$dirty) return errors
        if(typeof this.form.addressByID.city !== typeof {} && this.form.addressByID.city.length>0) 
          errors.push('Mohon masukan kab/kota sesuai pada pilihan.')
        if(this.form.addressByID.city.length==0) errors.push('Mohon masukan alamat kab/kota anda.')
        return errors
    },
    addressSubDistrictError() {
      const errors = []
        if (!this.$v.addressByID.subDistrict.$dirty) return errors
        if(typeof this.form.addressByID.subDistrict !== typeof {} && this.form.addressByID.subDistrict.length>0) 
          errors.push('Mohon masukan kelurahan sesuai pada pilihan.')
        if(this.form.addressByID.subDistrict.length==0) errors.push('Mohon masukan alamat kelurahan anda.')
        return errors
    },
    addressDistrictError() {
      const errors = []
        if (!this.$v.addressByID.district.$dirty) return errors
        if(typeof this.form.addressByID.district !== typeof {} && this.form.addressByID.district.length>0) 
          errors.push('Mohon masukan kecamatan sesuai pada pilihan.')
        if(this.form.addressByID.district.length==0) errors.push('Mohon masukan alamat kecamatan anda.')
        return errors
    },
    addressDomicileProvinceError() {
      const errors = []
        if (!this.$v.addressByDomicile.province.$dirty) return errors
        if(typeof this.form.addressByDomicile.province !== typeof {} && this.form.addressByDomicile.province.length>0) 
          errors.push('Mohon masukan provinsi sesuai pada pilihan.')
        if(this.form.addressByDomicile.province.length==0) errors.push('Mohon masukan alamat provinsi anda.')
        return errors
    },
    addressDomicileCityError() {
      const errors = []
        if (!this.$v.addressByDomicile.city.$dirty) return errors
        if(typeof this.form.addressByDomicile.city !== typeof {} && this.form.addressByDomicile.city.length>0) 
          errors.push('Mohon masukan kab/kota sesuai pada pilihan.')
        if(this.form.addressByDomicile.city.length==0) errors.push('Mohon masukan alamat kab/kota anda.')
        return errors
    },
    addressDomicileSubDistrictError() {
      const errors = []
        if (!this.$v.addressByDomicile.subDistrict.$dirty) return errors
        if(typeof this.form.addressByDomicile.subDistrict !== typeof {} && this.form.addressByDomicile.subDistrict.length>0) 
          errors.push('Mohon masukan kelurahan sesuai pada pilihan.')
        if(this.form.addressByDomicile.subDistrict.length==0) errors.push('Mohon masukan alamat kelurahan anda.')
        return errors
    },
    addressDomicileDistrictError() {
      const errors = []
        if (!this.$v.addressByDomicile.district.$dirty) return errors
        if(typeof this.form.addressByDomicile.district !== typeof {} && this.form.addressByDomicile.district.length>0) 
          errors.push('Mohon masukan kecamatan sesuai pada pilihan.')
        if(this.form.addressByDomicile.district.length==0) errors.push('Mohon masukan alamat kecamatan anda.')
        return errors
    },
  },
  watch: {
    accountData: function(newVal) {
      var response = newVal.data

      this.form.memberNo=response.memberNo
      this.form.branch=response.branch
      this.form.fullName=response.fullName
      this.form.tanzeem=response.tanzeem
      this.form.avatar=newVal.avatar
      this.form.nomorID=response.identityNumber
      this.form.label=response.identityType
      this.form.phoneNo=response.phone
      this.form.emailAddress=response.email
      this.form.pob=response.placeOfBirth
      this.form.dob=response.dateOfBirth==null?'1900-01-01':response.dateOfBirth
      this.form.gender=response.gender
      this.form.addressByID.rt=response.address.rt
      this.form.addressByID.rw=response.address.rw
      this.form.addressByID.district=response.address.kecamatan
      this.form.addressByID.subDistrict=response.address.kelurahan
      this.form.addressByID.city=response.address.kabupaten
      this.form.addressByID.province=response.address.provinsi
      this.form.addressByID.address=response.address.address
      
      this.form.addressByDomicile.rt=response.addressResidence.rt
      this.form.addressByDomicile.rw=response.addressResidence.rw
      this.form.addressByDomicile.district=response.addressResidence.kecamatan
      this.form.addressByDomicile.subDistrict=response.addressResidence.kelurahan
      this.form.addressByDomicile.city=response.addressResidence.kabupaten
      this.form.addressByDomicile.province=response.addressResidence.provinsi
      this.form.addressByDomicile.address=response.addressResidence.address

      this.adjustProvince()
      this.adjustCityAll()
      if (this.form.addressByDomicile.rt === this.form.addressByID.rt &&
          this.form.addressByDomicile.rw === this.form.addressByID.rw &&
          this.form.addressByDomicile.province === this.form.addressByID.province &&
          this.form.addressByDomicile.city === this.form.addressByID.city &&
          this.form.addressByDomicile.subDistrict === this.form.addressByID.subDistrict &&
          this.form.addressByDomicile.district === this.form.addressByID.district &&
          this.form.addressByDomicile.postCode === this.form.addressByID.postCode &&
          this.form.addressByDomicile.address === this.form.addressByID.address){
            this.form.cbAddress = 1
          }
      if(!this.$root.isError) this.$root.overlay = false
    },
    'form.nomorID': function() {
      if (this.form.nomorID.length>0) {
        if(this.form.nomorID.charAt(0).match(/\d+/g) === null) {
          this.form.label = 'PASSPORT'
          this.maxLengthID = 8
        }else if(this.form.nomorID.length > 8 && this.form.nomorID.length <= 12) {
          this.form.label = 'SIM'
        }else if(this.form.nomorID.length > 12) {
          this.form.label = 'KTP'
          this.maxLengthID = 16
        }else{
          this.maxLengthID = 16
        }
      }
      else {
        this.form.label = 'KTP/SIM/PASSPORT'
        this.maxLengthID = 16
      }
    },
    'form.phoneNo': function() {
      if (this.form.phoneNo.length>0) {
        this.validate.showMsg = false 
        this.validate.phoneNo = true
      }
      else this.validate.phoneNo = false
    },
    'form.pob': function() {
      if(typeof this.form.pob !== typeof {} && this.form.pob.length>0) this.validate.pob = false
      else if (typeof this.form.pob === typeof {} && this.form.pob.name.length>0 ) {
        this.validate.showMsg = false 
        this.validate.pob = true
      }
      else this.validate.pob = false
    },
    'form.dob': function() {
      if (this.form.dob.length>0) {
        this.validate.showMsg = false 
        this.validate.dob = true
      }
      else this.validate.dob = false
    },
    'form.gender': function() {
      if (this.form.gender.length>0) {
        this.validate.showMsg = false 
        this.validate.gender = true
      }
      else this.validate.gender = false
    },
    'form.addressByID.province': function() {
      this.getCity(this.form.addressByID.province.code, true)
      // this.form.addressByID.city = ''

      if(typeof this.form.addressByID.province !== typeof {} && this.form.addressByID.province.length>0) 
        this.validate.addressByID.province = false
      else if (typeof this.form.addressByID.province === typeof {} && this.form.addressByID.province.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.province = true
      }
      else this.validate.addressByID.province = false
    },
    'form.addressByID.city': function() {
      this.getDistrict(this.form.addressByID.city.code, true)
      // this.form.addressByID.district = ''

      if(typeof this.form.addressByID.city !== typeof {} && this.form.addressByID.city.length>0) 
        this.validate.addressByID.city = false
      else if (typeof this.form.addressByID.city === typeof {} && this.form.addressByID.city.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.city = true
      }
      else this.validate.addressByID.city = false
    },
    'form.addressByID.subDistrict': function() {
      if(typeof this.form.addressByID.subDistrict !== typeof {} && this.form.addressByID.subDistrict.length>0) 
        this.validate.addressByID.subDistrict = false
      else if (typeof this.form.addressByID.subDistrict === typeof {} && this.form.addressByID.subDistrict.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.subDistrict = true
      }
      else this.validate.addressByID.subDistrict = false
    },
    'form.addressByID.district': function() {
      this.getSubDistrict(this.form.addressByID.district.code, true)
      // this.form.addressByID.subDistrict = ''

      if(typeof this.form.addressByID.district !== typeof {} && this.form.addressByID.district.length>0) 
        this.validate.addressByID.district = false
      else if (typeof this.form.addressByID.district === typeof {} && this.form.addressByID.district.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.district = true
      }
      else this.validate.addressByID.district = false
    },
    'form.addressByDomicile.province': function() {
      this.getCity(this.form.addressByDomicile.province.code, false)
      // if(this.form.cbAddress == 0) this.form.addressByDomicile.city = ''

      if(typeof this.form.addressByDomicile.province !== typeof {} && this.form.addressByDomicile.province.length>0) 
        this.validate.addressByDomicile.province = false
      else if (typeof this.form.addressByDomicile.province === typeof {} && this.form.addressByDomicile.province.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.province = true
      }
      else this.validate.addressByDomicile.province = false
    },
    'form.addressByDomicile.city': function() {
      this.getDistrict(this.form.addressByDomicile.city.code)
      // if(this.form.cbAddress == 0) this.form.addressByDomicile.district = ''

      if(typeof this.form.addressByDomicile.city !== typeof {} && this.form.addressByDomicile.city.length>0) 
        this.validate.addressByDomicile.city = false
      else if (typeof this.form.addressByDomicile.city === typeof {} && this.form.addressByDomicile.city.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.city = true
      }
      else this.validate.addressByDomicile.city = false
    },
    'form.addressByDomicile.subDistrict': function() {
      if(typeof this.form.addressByDomicile.subDistrict !== typeof {} && this.form.addressByDomicile.subDistrict.length>0) 
        this.validate.addressByDomicile.subDistrict = false
      else if (typeof this.form.addressByDomicile.subDistrict === typeof {} && this.form.addressByDomicile.subDistrict.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.subDistrict = true
      }
      else this.validate.addressByDomicile.subDistrict = false
    },
    'form.addressByDomicile.district': function() {
      this.getSubDistrict(this.form.addressByDomicile.district.code, false)
      // if(this.form.cbAddress == 0) this.form.addressByDomicile.subDistrict = ''

      if(typeof this.form.addressByDomicile.district !== typeof {} && this.form.addressByDomicile.district.length>0) 
        this.validate.addressByDomicile.district = false
      else if (typeof this.form.addressByDomicile.district === typeof {} && this.form.addressByDomicile.district.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.district = true
      }
      else this.validate.addressByDomicile.district = false
    },
  },
  methods: {
    checkFormAddress() {
      if (this.form.cbAddress==1) {
        this.form.addressByDomicile.rt=this.form.addressByID.rt
        this.form.addressByDomicile.rw=this.form.addressByID.rw
        this.form.addressByDomicile.province=this.form.addressByID.province
        this.form.addressByDomicile.city=this.form.addressByID.city
        this.form.addressByDomicile.subDistrict=this.form.addressByID.subDistrict
        this.form.addressByDomicile.district=this.form.addressByID.district
        this.form.addressByDomicile.postCode=this.form.addressByID.postCode
        this.form.addressByDomicile.address=this.form.addressByID.address
      }
    },
    checkValid() {
      if (this.validate.phoneNo && 
          this.validate.pob && 
          this.validate.dob && 
          this.validate.gender && 
          this.validate.addressByID.province && 
          this.validate.addressByID.city && 
          this.validate.addressByID.subDistrict && 
          this.validate.addressByID.district && 
          this.validate.addressByDomicile.province && 
          this.validate.addressByDomicile.city && 
          this.validate.addressByDomicile.subDistrict && 
          this.validate.addressByDomicile.district) return true 
      else {
        this.validate.showMsg = true 
        return false
      }
    },
    async updateData(){
      this.isLoad = true
      if(!this.checkValid()){
        this.isLoad = false
        return
      }

      const User = new FormData()
      User.append("avatarFile", this.form.avatarFile)
      User.append("request", JSON.stringify({
          address: {
              address: this.form.addressByID.address,
              kabupaten: this.form.addressByID.city.name,
              kecamatan: this.form.addressByID.district.name,
              kelurahan: this.form.addressByID.subDistrict.name,
              provinsi: this.form.addressByID.province.name,
              rt: this.form.addressByID.rt,
              rw: this.form.addressByID.rw
          },
          addressResidence: {
              address: this.form.addressByDomicile.address,
              kabupaten: this.form.addressByDomicile.city.name,
              kecamatan: this.form.addressByDomicile.district.name,
              kelurahan: this.form.addressByDomicile.subDistrict.name,
              provinsi: this.form.addressByDomicile.province.name,
              rt: this.form.addressByDomicile.rt,
              rw: this.form.addressByDomicile.rw
          },
          
          branch: this.form.branch,
          dateOfBirth: this.form.dob,
          fullName: this.form.fullName,
          gender: this.form.gender,
          phone: this.form.phoneNo,
          placeOfBirth: this.form.pob.name,
          tanzeem: this.form.tanzeem,
          identityNumber: this.form.nomorID,
          identityType: this.form.label
      }))

      await this.$root.api.sentAPI(
        {
          method: 'post',
          api: this.$root.api.update.member,
          form: User
        })
        .then(res => {
          // console.log('res ' + JSON.stringify(res))
          this.$emit('onUpdateAccount', res.data)
          this.snackbar = true
          if(res.status.code === 200){
            this.message = res.status.message
          }else{
            this.message = res.status.message
          }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
        this.isLoad = false
    },
    adjustProvince(){
        for (let i = 0; i < this.listProvince.length; i++) {
          if(this.listProvince[i].name === this.form.addressByID.province){
            this.form.addressByID.province = this.listProvince[i]
          }
          if(this.listProvince[i].name === this.form.addressByDomicile.province){
            this.form.addressByDomicile.province = this.listProvince[i]
          }
        }
    },
    adjustCityAll(){
        for (let i = 0; i < this.form.listCityAll.length; i++) {
          if(this.form.listCityAll[i].name === this.form.pob){
            this.form.pob = this.form.listCityAll[i]
          }
        }
    },
    onFileSelect(e) {
      this.limitSize = false
      const file = e.target.files[0]
      if (file.size > 1000000) {
        this.limitSize = true
        return
      }
      this.form.avatarFile = file
      if (typeof FileReader === 'function') {
        // this.dialog = true
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          // this.selectedFile = event.target.result
          // this.$refs.cropper.replace(this.selectedFile)
          // console.log(reader.result)
          this.form.newAvatar = reader.result
        }
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async getCity(provinceID, isByID) {
      // get city
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.city + provinceID
      }).then(res => {
        if(isByID){
          this.form.addressByID.listCity = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listCity.push({
              code: res.data[i].kabKotId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByID.city){
              this.form.addressByID.city = {
                code: res.data[i].kabKotId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }else{
          this.form.addressByDomicile.listCity = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listCity.push({
              code: res.data[i].kabKotId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByDomicile.city){
              this.form.addressByDomicile.city = {
                code: res.data[i].kabKotId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getDistrict(cityID, isByID) {
      // get district
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.district + cityID
      }).then(res => {
        if(isByID) {
          this.form.addressByID.listDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listDistrict.push({
              code: res.data[i].kecamatanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByID.district){
              this.form.addressByID.district = {
                code: res.data[i].kecamatanId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }else{
          this.form.addressByDomicile.listDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listDistrict.push({
              code: res.data[i].kecamatanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByDomicile.district){
              this.form.addressByDomicile.district = {
                code: res.data[i].kecamatanId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getSubDistrict(districtID, isByID) {
      // get sub district
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.subDistrict + districtID
      }).then(res => {
        if(isByID){
          this.form.addressByID.listSubDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listSubDistrict.push({
              code: res.data[i].kelurahanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByID.subDistrict){
              this.form.addressByID.subDistrict = {
                code: res.data[i].kelurahanId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }else{
          this.form.addressByDomicile.listSubDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listSubDistrict.push({
              code: res.data[i].kelurahanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
            if(this.$root.capitalFirstChart(res.data[i].nama) === this.form.addressByDomicile.subDistrict){
              this.form.addressByDomicile.subDistrict = {
                code: res.data[i].kelurahanId,
                name: this.$root.capitalFirstChart(res.data[i].nama)
              }
            }
          }
        }
          
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getProvince(){
      // get province
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.province
      }).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.listProvince.push({
              code: res.data[i].provinsiId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
          this.adjustProvince()
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getCityAll(){
      // get all city
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.cityAll
      }).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.form.listCityAll.push({
              code: res.data[i].kabKotId,
              name: this.$root.capitalFirstChart(this.$root.capitalFirstChart(res.data[i].nama))
            }) 
          }
          this.adjustCityAll()
      })
      .catch((err) => {
          console.log(err)
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    }
  },
  created(){
    var response = this.accountData
    if(response !== null){
      response = this.accountData.data
      
      this.form.memberNo=response.memberNo
      this.form.branch=response.branch
      this.form.fullName=response.fullName
      this.form.tanzeem=response.tanzeem
      this.form.avatar=this.accountData.avatar
      this.form.nomorID=response.identityNumber
      this.form.label=response.identityType
      this.form.phoneNo=response.phone
      this.form.emailAddress=response.email
      this.form.pob=response.placeOfBirth
      this.form.dob=response.dateOfBirth==null?'1900-01-01':response.dateOfBirth
      this.form.gender=response.gender
      this.form.addressByID.rt=response.address.rt
      this.form.addressByID.rw=response.address.rw
      this.form.addressByID.district=response.address.kecamatan
      this.form.addressByID.subDistrict=response.address.kelurahan
      this.form.addressByID.city=response.address.kabupaten
      this.form.addressByID.province=response.address.provinsi
      this.form.addressByID.address=response.address.address
      
      this.form.addressByDomicile.rt=response.addressResidence.rt
      this.form.addressByDomicile.rw=response.addressResidence.rw
      this.form.addressByDomicile.district=response.addressResidence.kecamatan
      this.form.addressByDomicile.subDistrict=response.addressResidence.kelurahan
      this.form.addressByDomicile.city=response.addressResidence.kabupaten
      this.form.addressByDomicile.province=response.addressResidence.provinsi
      this.form.addressByDomicile.address=response.addressResidence.address

      this.getProvince()
      this.getCityAll()
      if (this.form.addressByDomicile.rt === this.form.addressByID.rt &&
          this.form.addressByDomicile.rw === this.form.addressByID.rw &&
          this.form.addressByDomicile.province === this.form.addressByID.province &&
          this.form.addressByDomicile.city === this.form.addressByID.city &&
          this.form.addressByDomicile.subDistrict === this.form.addressByID.subDistrict &&
          this.form.addressByDomicile.district === this.form.addressByID.district &&
          this.form.addressByDomicile.postCode === this.form.addressByID.postCode &&
          this.form.addressByDomicile.address === this.form.addressByID.address){
            this.form.cbAddress = 1
          }
    }
  },
  async mounted(){
    this.getProvince()
    this.getCityAll()
  },
  updated() {
    this.$emit('onChange', this.isChange)
  }
}
</script>
<style scoped>
.row{
  margin-bottom: 10px;
}
.card {
  width: 925px;
  text-align: left;
}
.card-form{
  min-height: 170px;
}
.input{
  margin-top: 30px;
}
</style>
