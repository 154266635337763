<template>
  
  <div >
    <!-- e9e5df -->
      <v-main>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-card class="mx-auto" width="800" outlined>
                        <v-row>
                            <v-col cols="12" class="pa-5">
                                <h3 :class="`headline font-weight-light mb-4`">
                                    Mutasi Rekening
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" class="pl-5 pr-5 mb-n5">
                                <p style="text-align: left" class="mb-1">Jenis Simpanan:</p>
                                <b-form-select v-model="filter.depositeType" :options="depositeTypes"></b-form-select>
                            </v-col>
                            <v-col cols="12" md="4" class="pl-5 pr-5 mb-n5">
                                <p style="text-align: left" class="mb-1">Periode Mutasi dari:</p>
                                <b-form-datepicker size="md" v-model="filter.periodeFrom" :min="min"></b-form-datepicker>
                            </v-col>
                            <v-col cols="12" md="4" class="pl-5 pr-5 ">
                                <p style="text-align: left" class="mb-1">Periode Mutasi sampai:</p>
                                <b-form-datepicker size="md" v-model="filter.periodeTo" :min="min"></b-form-datepicker>
                            </v-col>
                        </v-row>
                        <v-row v-if="message !== null">
                          <v-col cols="12" align="left" class="pl-5">
                            <h1 :class="`text-caption font-weight-light mt-4`" style="color:red">{{message}}</h1>
                          </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" align="left" class="pl-5">
                                <v-btn color="primary" class="mr-5" @click="filtering">Tampilkan</v-btn>
                                <v-btn color="error" @click="reset">Reset</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="pl-3 pr-3">
                <v-card class="mx-auto" width="800" outlined>
                    <v-row class="justify-content-center">
                        <v-col md="5">
                            <span style="font-size: 0.75rem">Total Debit</span>
                            <br />
                            <span>{{$root.currencyFormat(data.totalDischarge)}}</span>
                        </v-col>
                        <v-divider vertical />
                        <v-col md="5">
                            <span style="font-size: 0.75rem">Total Kredit</span>
                            <br />
                            <span>{{$root.currencyFormat(data.totalCredit)}}</span>
                        </v-col>
                    </v-row>
                    <v-row class="justify-content-center">
                      <v-col md="12">
                            <span style="font-size: 0.9rem">Total Saldo</span>
                            <br />
                            <span>{{$root.currencyFormat(data.totalBalance)}}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card
                        class="mx-auto overflow-y-auto mb-16"
                        width="800"
                        max-height="100vh"
                        outlined>
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="grey"
                            indeterminate
                            class="mt-10 mb-10"
                            v-if="loadFilter"
                        ></v-progress-circular>
                        <template v-if="data.listTrans.length == 0 && !loadFilter">
                            <p :style="{color:'#A3A3A3', textAlign:'left'}" class="pa-5"><i>Tidak ada transaksi</i></p>
                        </template>
                        <v-timeline :dense=dense align-top v-if="!loadFilter && data.listTrans.length != 0">
                            <template v-for="(data, i) in data.listTrans">
                                <v-timeline-item
                                    :key="i"
                                    :color="data.color"
                                    small
                                    :left="data.title !== 'Debit' ? true : false"
                                    :right="data.title === 'Debit' ? true : false"
                                    >
                                    <div :style="{textAlign: dense ? 'left' : data.title === 'Debit' ? 'left' : 'right'}">
                                        <h2 :class="`headline font-weight-light mb-4`">
                                            {{data.deposite}}
                                        <h2 style="font-size:12px">{{$root.formatDate(data.dateTransc)}}</h2>
                                        </h2>
                                        <div>
                                            {{data.desc}}
                                        </div>
                                        <div>
                                            <h3 :class="`headline font-weight-light mb-4 ${data.color}--text`">
                                                <span>{{data.title === 'Debit' ? '+' : '-'}}</span> Rp{{$root.currencyFormat(data.amount)}}
                                            </h3>
                                        </div>
                                    </div>
                                    </v-timeline-item>
                                    <v-divider :inset=dense :key="i+10" />
                            </template>
                        </v-timeline>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  name: 'Mutation',
  components: {
    OverlayProgress
  },
  data() {
    return {
      dense: false,
      depositeTypes: [],
      filter: {
          depositeType: '',
          periodeFrom: '',
          periodeTo: ''
      },
      data:{
        totalBalance: 0,
        totalDischarge: 0,
        totalCredit: 0,
        listTrans: []
      },
      min: '',
      max: new Date(),
      maxPeriode: new Date(),
      loadFilter: false,
      message: null
    }
  },
  methods: {
      filtering() {
          this.loadFilter = true
          this.data.listTrans = []
          if(this.filter.depositeType !== '' && (this.filter.periodeFrom === '' && this.filter.periodeTo === '')){
            // console.log('Fileter by jenis simpanan')
            this.$root.api.sentAPI(
            {
              method: 'get',
              api: this.$root.api.get.mutation + this.filter.depositeType
            }).then(res => {
                // console.log(JSON.stringify(res))
                var response = res.data
                var deposite = ''

                this.data.totalBalance = response.amountFinal
                this.data.totalDischarge = response.amountInsFinal
                this.data.totalCredit = response.amountOutFinal

                for (let j = 0; j < this.depositeTypes.length; j++) {
                  if(this.depositeTypes[j].value === this.filter.depositeType)
                    deposite = this.depositeTypes[j].text
                  
                }

                for (let i = 0; i < response.details.length; i++) {
                    this.data.listTrans.push({
                      color: response.details[i].amountIns !== 0 ? 'green' : 'pink',
                      title: response.details[i].amountIns !== 0 ? 'Debit' : 'Kredit',
                      deposite: deposite,
                      desc: response.details[i].description,
                      dateTransc: response.details[i].dateTransaction,
                      amount: response.details[i].amountIns !== 0 ? response.details[i].amountIns : response.details[i].amountOut
                    })
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            .finally(() => {
              this.loadFilter = false
            })
          }
          else if(this.filter.depositeType !== '' && this.filter.periodeFrom !== '' && this.filter.periodeTo !== ''){
            // console.log('filter by all method ' + this.filter.depositeType)
            this.$root.api.sentAPI(
            {
              method: 'get',
              api: this.$root.api.get.mutation + this.filter.periodeFrom + '/' + this.filter.periodeTo + '/' + this.filter.depositeType
            }).then(res => {
                // console.log(JSON.stringify(res))
                var response = res.data
                var deposite = ''

                this.data.totalBalance = response.amountFinal
                this.data.totalDischarge = response.amountInsFinal
                this.data.totalCredit = response.amountOutFinal

                for (let j = 0; j < this.depositeTypes.length; j++) {
                  if(this.depositeTypes[j].value === this.filter.depositeType)
                    deposite = this.depositeTypes[j].text
                  
                }

                for (let i = 0; i < response.details.length; i++) {
                    this.data.listTrans.push({
                      color: response.details[i].amountIns !== 0 ? 'green' : 'pink',
                      title: response.details[i].amountIns !== 0 ? 'Debit' : 'Kredit',
                      deposite: deposite,
                      desc: response.details[i].description,
                      dateTransc: response.details[i].dateTransaction,
                      amount: response.details[i].amountIns !== 0 ? response.details[i].amountIns : response.details[i].amountOut
                    })
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            .finally(() => {
              this.loadFilter = false
            })
          }
          else if(this.filter.depositeType === '' && (this.filter.periodeFrom !== '' && this.filter.periodeTo !== '')){
            this.message = 'Harap pilih jenis simpanan'
            this.loadFilter = false
          }
          else if(this.filter.periodeFrom !== '' && this.filter.periodeTo === ''){
            this.message = 'Harap pilih periode mutasi sampai'
            this.loadFilter = false
          }
          else if(this.filter.periodeFrom === '' && this.filter.periodeTo !== ''){
            this.message = 'Harap pilih periode mutasi dari'
            this.loadFilter = false
          }
          else{
            this.message = 'Harap pilih dahulu filter'
            this.loadFilter = false
          }
      },
      reset() {
          this.data.listTrans = []
          this.data.totalCredit = 0
          this.data.totalDischarge = 0
          this.data.totalBalance = 0
          this.filter.depositeType = '',
          this.filter.periodeFrom = '',
          this.filter.periodeTo = ''
      },
      setMinDate(){
        var date = new Date()
        var limitMin = new Date(date.getFullYear(), date.getMonth(), date.getDate())

        const minDate = new Date(limitMin)
        minDate.setMonth(minDate.getMonth()-2)
        minDate.setDate(minDate.getDate())
        
        this.min = minDate  
      }
  },
  watch: {
    'filter.depositeType': function() {
      this.message = null
    },
    'filter.periodeTo': function() {
      this.message = null
    },
    'filter.periodeFrom': function() {
        this.message = null
        this.filter.periodeTo = ''
    },
  },
  created() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          this.dense = true
          break
          case 'sm': 
          this.dense = false
          break
          case 'md': 
          this.dense = false
          break
          case 'lg': 
          this.dense = false
          break
          case 'xl': 
          this.dense = false
          break
        }
  },
  async mounted() {
    await this.$root.api.sentAPI(
    {
      method: 'get',
      api: this.$root.api.get.simpananList
    }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          this.depositeTypes.push({
            value: res.data[i].simpananId,
            text: this.$root.capitalFirstChart(res.data[i].simpananName)
          })
        }
    })
    .catch((err) => {
        this.$root.isError = true
        if(!navigator.onLine) this.$root.isError = err.message
    })
    .finally(() => {
      if(!this.$root.isError) this.$root.overlay = false
    })
    this.setMinDate()
  },
}
</script>
<style>
.v-timeline-item__opposite {
    align-self: flex-start !important;
}
</style>
