import axios from 'axios'
import store from '../../store'
import router from '../../router'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const get = {
    simpananList: 'bufu/simpanan-list/get',

    // login-register page
    branch: 'bufu/branch/get',
    verify: 'bufu/member/verify',
    province: 'bufu/address/select-prov',
    city: 'bufu/address/select-kab/',
    cityAll: 'bufu/address/select-kab/all',
    district: 'bufu/address/select-kec/',
    subDistrict: 'bufu/address/select-kel/',

    // home page
    saldo: 'bufu/saldo/get',

    // mutation page
    mutation: 'bufu/mutasi/',

    // savings page
    periode: 'bufu/month-slot/select',

    // profile page
    bill: 'bufu/rekening/get',
    account: 'bufu/member/get',
    // balance: 'bufu/saldo/get',
    avatar: 'bufu/member/avatar',

    bank: 'bufu/rekening-list/get',
    invoice: 'bufu/member/redirect'
}

const post = {
    login: 'bufu/authenticate',
    logout: 'bufu/logout',
    member: 'bufu/member/create',

    forgotPassword: 'bufu/member/forgotPassword',
    verifyOTP: 'bufu/verifyOtp',
    changeForgotPassword: 'bufu/member/changeForgotPassword',
    changePassword: 'bufu/member/changePassword',
    changeEmail: 'bufu/member/changeEmail',

    bill: 'bufu/rekening',

    savings: 'bufu/v1/simpanan/create',
    uploadVerification: 'bufu/member/file/upload',

    cashWithdrawal: 'bufu/trx/cashWithdrawal'
}

const update = {
    member: 'bufu/member/update'
}

function sentAPI(param) {
    if (store.getters.accessToken !== null) axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.accessToken
    else axios.defaults.headers.common['Authorization'] = ''

    return new Promise(function(resolve, reject) {
        let response = null
        let error = null

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        axios({
            url: param.api,
            method: param.method,
            data: param.form,
            headers: headers,
        }).then(res => {
            response = res.data
            resolve(response)
        }).catch((err) => {
            error = err
            if (error.message === 'Request failed with status code 401') {
                store.dispatch('LogOut')
                router.push('/home').catch(() => {})
            } else reject(error)
        })
    })
}

const api = {
    sentAPI,
    get,
    post,
    update
}

export default api