<template>
    <v-alert
        :border="border"
        :color="color"
        dark
        v-show="show"
    >
        {{message}}
    </v-alert>
</template>
<script>
export default {
    props: ['border','color','show','message']
}
</script>