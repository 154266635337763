import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Mutation from '../views/Mutation.vue'
import Savings from '../views/Savings.vue'
import Withdrawal from '../views/Withdrawal.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/mutation',
        name: 'Mutation',
        component: Mutation,
        meta: { requiresAuth: true }
    },
    {
        path: '/topup',
        name: 'Savings',
        component: Savings,
        meta: { requiresAuth: true }
    },
    {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: Withdrawal,
        meta: { requiresAuth: true }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue'),
        meta: { guest: true }
    },
    {
        path: '/home',
        name: 'Dashboard',
        component: () =>
            import ('../views/Dashboard.vue'),
        meta: { guest: true }
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('../views/Register.vue'),
        meta: { guest: true }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () =>
            import ('../views/ResetPassword.vue'),
        props: route => ({ auth: route.query.auth, target: route.query.target }),
        meta: { guest: true }
    },
    {
        path: '/confirmation',
        name: 'Confirmation',
        component: () =>
            import ('../views/Confirmation.vue'),
        props: route => ({ status: route.query.status }),
        meta: { guest: true }
    },
    {
        path: '/information-kbufu',
        name: 'Information',
        component: () =>
            import ('../views/Information.vue'),
        props: route => ({ status: route.query.status }),
        meta: { guest: true }
    },
    {
        path: '/faq/general-questions',
        name: 'GeneralQuestions',
        component: () =>
            import ('../views/faq/GeneralQuestions.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () =>
            import ('../views/AboutUs.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/developer/managed/deploy',
        name: 'Managed',
        component: () =>
            import ('../views/developer/Deploy.vue'),
        meta: { guest: true }
    },
    {
        path: '/:catchAll(.*)',
        component: () =>
            import ('../views/NotFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (from.matched.some(record => record.path === '/login'))
        store.commit('getAvatar', true)
    if (to.matched.some(record => record.path !== '/reset-password'))
        store.commit('setValidateReset', null)
    if (to.matched.some(record => record.path !== '/confirmation'))
        store.commit('setValidateConfirmation', null)
    if (from.matched.some(record => record.path === '/confirmation'))
        store.commit('setValidateRegister', null)
    if (to.matched.some(record => record.path == '/register')) {
        if (store.getters.isValidateRegister) {
            next()
            return
        }
        next('/home')
    } else if (to.matched.some(record => record.path == '/reset-password')) {
        if (store.getters.isValidateReset) {
            next()
            return
        }
        next('/home')
    } else if (to.matched.some(record => record.path == '/confirmation')) {
        if (store.getters.isValidateConfirmation) {
            next()
            return
        }
        next('/home')
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/home')
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});

export default router