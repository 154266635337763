<template>
<b-container>
<v-row dense>
    <template v-for="bank in listBill">
        <v-col md="4" :key="bank.id" style="cursor:pointer">
            <v-card style="padding:10px">
                <v-card-text @click="onClickItem(bank)">
                    <img :alt="bank.bankName" :src="require('../../assets/bank/' + bank.bankLogo)" width="100">
                    <h5 class="text--primary">
                        {{bank.bankName}}
                    </h5>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="deep-purple accent-4"
                        @click="onClickModal(bank)"
                    >
                        EDIT REKENING
                    </v-btn>
                    <v-icon color="green" v-if="bank.isUsed == 1">mdi-check-circle</v-icon>
                </v-card-actions>
                <v-card-text>
                    <div :style="{backgroundColor:bank.status==53?'green':bank.status==52?'black':'red', color:'white', paddingLeft:'5px'}">
                        {{bank.statusDesc}}
                    </div>
                    <span v-if="bank.status==52">
                        <hr>{{bank.remark}}
                    </span>
                </v-card-text>
            </v-card>
        </v-col>
    </template>
    <template v-if="listBill.length == 0">
        <p :style="{color:'#A3A3A3'}"><i>Rekening belum ditambahkan</i></p>
    </template>
</v-row>
 <BillItemBatch ref="batchBillItem" :listBill=list :mode=mode :billData=billData @onUpdate=onUpdate />
</b-container>
</template>
<script>
import BillItemBatch from '@/components/modal/BillItemBatch.vue'
import bank from '../../lib/json/list-bank.json'
export default {
    name: 'BillItem',
    props: ['bank','list','mode'],
    components: {
        BillItemBatch
    },
    data() {
        return {
            listBill: this.list,
            billData: ''
        }
    },
    watch: {
        list: function(newVal){
            this.listBill = newVal
            
            for (let a = 0; a < this.listBill.length; a++) {
                for (let b = 0; b < bank.data.length; b++) {
                    if(bank.data[b].bankCode === this.listBill[a].bankCode) {
                    this.listBill[a].bankName = bank.data[b].bankName
                    this.listBill[a].bankLogo = bank.data[b].bankLogo
                    }
                    
                }
            }
        }
    },
    methods: {
        onClickItem(bank) {
            var idx
            for(idx in this.listBill) {
                this.listBill[idx].isUsed = 0
            }
            bank.isUsed = 1
            this.$emit('onChange', true)
            this.$root.api.sentAPI(
            {
                method: 'post',
                api: this.$root.api.post.bill,
                form: {
                    bankCode: bank.bankCode,
                    name: bank.accountName,
                    noRek: bank.accountNumber,
                    isPriority: bank.isUsed
                }
            }).then(() => {
                // console.log(JSON.stringify(res))
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
        },
        onClickModal(bank) {
            this.billData = bank
            this.$refs.batchBillItem.show()
        },
        onUpdate(value){
            this.listBill = value

            for (let a = 0; a < this.listBill.length; a++) {
                for (let b = 0; b < bank.data.length; b++) {
                    if(bank.data[b].bankCode === this.listBill[a].bankCode) {
                    this.listBill[a].bankName = bank.data[b].bankName
                    this.listBill[a].bankLogo = bank.data[b].bankLogo
                    }
                    
                }
            }
            this.$emit('onUpdate', value)
        }
    }
}
</script>