<template>
    <b-container fluid :style="{padding: '0px 0px'}">
        <b-row class="justify-content-center row">
            <b-card>
                    <v-btn :disabled="isDisable" color="primary" @click="onClickModal"><v-icon>mdi-plus</v-icon> Tambah Rekening</v-btn>
                    <br />
                    <h1 v-if="isDisable" :class="`text-caption font-weight-light`" style="color:red">Harap verifikasi dahulu KTP dan PAS FOTO</h1>
            </b-card>
        </b-row>
        <b-row>
            <b-card>
                <BillItem :list=newListBill :mode='`edit`' @onChange=onChange @onUpdate=onUpdate />
            </b-card>
        </b-row>
        <BillItemBatch ref="batchBillItem" :listBill=newListBill :mode='`add`' @onUpdate=onUpdate />
    </b-container>
</template>

<script>

import bank from '../../lib/json/list-bank.json'

import BillItem from './BillItem.vue'
import BillItemBatch from '@/components/modal/BillItemBatch.vue'

export default {
  name: 'Bill',
  props: ['listBill','accountData'],
  components: {
      BillItem,
      BillItemBatch
  },
  data() {
    return {
        newListBill: [],
        isDisable: true
    }
  },
  created() {
    this.validate(this.accountData.data)
    this.newListBill = this.listBill
    for (let a = 0; a < this.newListBill.length; a++) {
      for (let b = 0; b < bank.data.length; b++) {
        if(bank.data[b].bankCode === this.newListBill[a].bankCode) {
          this.newListBill[a].bankName = bank.data[b].bankName
          this.newListBill[a].bankLogo = bank.data[b].bankLogo
        }
        
      }
    }
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event)
    },
    onUpdate(value){
      // console.log('value ' + JSON.stringify(value))
      this.newListBill = value
      this.$emit('onUpdateBill', value)
    },
    onClickModal() {
      this.$refs.batchBillItem.show()
    },
    validate(data) {
      if(!(data.ktpFileName == null || data.ktpFileName === 'default.jpg') 
        && !(data.pasFotoFileName == null || data.pasFotoFileName === 'default.jpg')) {
            this.isDisable = false
        }
    }
  },
}
</script>
<style scoped>
.row{
  margin-bottom: 10px;
}
.card {
  width: 925px;
  text-align: left;
}
</style>
