<template>   
<v-app>
  <div id="app" :style="{ backgroundImage: createBackgroundString, minHeight:'100vh' }">
    <div class="update-dialog" v-if="prompt">
      <div class="update-dialog__content">
        Versi baru terdeteksi. Refresh untuk memuat?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Perbarui
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="prompt = false"
        >
          Tutup
        </button>
      </div>
    </div>
    <Navbar v-show="isAuthenticated" :role = role :drawer=drawer :showBottomNav=showBottomNav @clicked = "onUpdate" />
    <LoginBar v-if="$route.name === 'Dashboard'" />
    <!-- banner alert topup -->
    <v-banner color="error" dark v-if="isAuthenticated && periodes.length > 0" :style="showBottomNav ? {textAlign:'left', fontSize:'14px', marginTop:'56px'}:{textAlign:'left', fontSize:'14px'}">
      Anda belum membayar <b>SIMPANAN WAJIB</b> bulan <b>{{periodes.join(', ')}}</b>. Silahkan untuk segera membayar / hubungi CS kami untuk pengecekan lanjut. Jazakallah khair
      <template v-slot:actions>
        <v-btn
          text
          color="white"
          to="/topup"
          style="text-decoration:none"
        >
          Bayar
        </v-btn>
      </template>
    </v-banner>

    <!-- banner notif verification -->
    <v-banner color="white" v-if="isAuthenticated && (!verifKtp || !verifPasFoto)" :style="showBottomNav && periodes.length <= 0 ? {textAlign:'left', fontSize:'14px', marginTop:'56px'}:{textAlign:'left', fontSize:'14px'}">
      Mohon untuk segera verifikasi <b>{{!verifKtp ? 'KTP' : ''}}</b> {{!verifKtp && !verifPasFoto ? ' dan ':''}} <b>{{!verifPasFoto ? 'PAS FOTO' : ''}}</b>, untuk keperluan pendataan dan pencetakan KTA Koperasi, <router-link style="text-decoration:none" to="/faq/general-questions#verification"><b>informasi lebih lanjut</b></router-link>. Jazakallah khair
    </v-banner>
    <router-view/>
  </div>
  <!--footer login-->
  <v-footer
    dark
    padless
    v-if="$route.name === 'Dashboard'"
  >
    <v-card
      flat
      tile
      class="indigo lighten-1 white--text text-center"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-4 white--text"
          icon
          @click="goto(icon.type)"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Jl. Sunan Gunung Jati No.1 Kelurahan Paninggilan Kecamatan Ciledug Kota Tangerang.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <small><span>Copyright &#169; {{ new Date().getFullYear() }}</span> <br v-if="showBottomNav" /> Koperasi Bina Umati Fadhal Umar</small>
      </v-card-text>
    </v-card>
  </v-footer>
  <!--footer home-->
  <v-footer
    v-if="isAuthenticated"
    padless
    class="indigo lighten-1 white--text"
    dark
    :style="showBottomNav ? {alignItems:'baseline', marginBottom:'56px'} : {alignItems:'baseline'}">
      
        <v-container style="width:80%" class="justify-content-center">
          <!--<v-row>
            <v-col md="1" align-self="center" :align="showBottomNav ? 'center' : 'left'">
                <v-icon style="cursor:pointer" @click="goto('whatsapp')">
                  mdi-youtube
                </v-icon>
            </v-col>
            <v-col md="1" align-self="center" :align="showBottomNav ? 'center' : 'left'">
                <v-icon style="cursor:pointer">
                  mdi-facebook
                </v-icon>
            </v-col>
            <v-col md="1" align-self="center" :align="showBottomNav ? 'center' : 'left'">
                <v-icon style="cursor:pointer" @click="goto('instagram')">
                  mdi-instagram
                </v-icon>
            </v-col>
            <v-col md="1" align-self="center" :align="showBottomNav ? 'center' : 'left'">
                <v-icon style="cursor:pointer">
                  mdi-twitter
                </v-icon>
            </v-col>
            <v-col md="1" align-self="center" :align="showBottomNav ? 'center' : 'left'">
                <v-icon style="cursor:pointer" @click="goto('telegram')">
                  mdi-send-outline
                </v-icon>
            </v-col>
            <v-col cols="12" md="7" align="right" v-if="!showBottomNav">
              <img alt="Bufu logo" src="./assets/bufu-logo-footer.png" width="180">
            </v-col>
          </v-row>-->
          <v-row>
            <v-col cols="12" md="3" align="left">
              <p style="font-size:14px; cursor: pointer" >FAQ</p>
              <p style="font-size:13px; color:#c9c9c9; cursor: pointer"  @click="routeTo('/faq/general-questions')">Pertanyaan Umum</p>
            </v-col>
            <!--<v-col cols="12" md="3" align="left">
                <p style="font-size:14px; cursor: pointer" @click="routeTo('/about-us')">Tentang kami</p>
                <p style="font-size:13px; color:#7e859d; cursor: pointer" @click="contact = !contact">Kontak</p>
                <template v-if="contact">
                  <a style="text-decoration:none" :href="contactWA()">
                    <v-icon class="mr-3">mdi-whatsapp</v-icon>
                  </a>
                  <a style="text-decoration:none" :href="contactEmail()">
                    <v-icon>mdi-email</v-icon>
                  </a>
                </template>
            </v-col>-->
          </v-row>
          <v-divider />
          <!--<v-row>
            <v-col cols="12" md="6" align="left">
              <p style="font-size:14px;">Visi</p>
                <p style="font-size:12px; color:#7e859d;">
                  Menjadi koperasi yang <b>terpercaya</b>, <b>bermanfaat</b>, <b>sukses</b>, <b>tangguh</b> dan memberikan <b>pelayanan prima</b> kepada anggota koperasi dan masyarakat dalam meningkatkan pendapatan koperasi dan kesejahteraan anggota.
                </p>
            </v-col>
            <v-col cols="12" md="6" align="left">
              <p style="font-size:14px;">Misi</p>
              <ul style="font-size:12px; color:#7e859d;">
                <li>Menjalin silaturahmi antara pengurus dan anggota dalam menjalankan koperasi supaya tetap tangguh dan berhasil.</li>
                <li>Mengusahakan penyediaan kebutuhan pokok anggota dan masyarakat berupa barang-barang primer dan sekunder.</li>
                <li>Membantu anggota dalam bermitra usaha dan memasarkan barang atau hasil produksi yang berkualitas, dibutuhkan oleh masyarakat dan harganya kompetitif.</li>
                <li>Meningkatkan pelayanan yang prima kepada anggota koperasi dan pelanggan lainnya.</li>
                <li>Meningkatkan peran serta anggota koperasi dalam pengembangan koperasi ke arah yang lebih tangguh dan maju dengan prinsip efisien, efektif dan produktif.</li>
                <li>Mengelola manajemen koperasi dengan profesional.</li>
                <li>Meningkatkan jejaring kemitraan dengan anggota, koperasi atau pihak lain dalam pembinaan dan pengembangan koperasi di dalam negeri maupun di luar negeri.</li>
              </ul>
            </v-col>
          </v-row>-->
          <v-row>
            <v-col :align="showBottomNav ? 'center' : 'left'">
              <small><span style="color:#c9c9c9;">Copyright &#169; {{ new Date().getFullYear() }}</span> <br v-if="showBottomNav" /> Koperasi Bina Umati Fadhal Umar</small>
            </v-col>
          </v-row>
        </v-container>
  </v-footer>
</v-app>
</template>
<script>

import Navbar from '@/components/Navbar.vue'
import LoginBar from '@/components/LoginBar.vue'
import store from "./store"
import {contactWA, contactEmail, socialMediaIG, socialMediaYoutube, socialMediaTelegram} from '@/lib/util/utilities.js'
import moment from "moment"

export default {
  components:{
    Navbar,
    LoginBar
  },
  data() {
    return {
      isAuthenticated: store.getters.isAuthenticated,
      role: store.getters.role,
      drawer: null,
      showBottomNav: false,
      contact: false,
      prompt:false,
      periodes: [],
      bannerShow: false,
      verifKtp: true,
      verifPasFoto: true,
      icons: [
        {icon: 'mdi-whatsapp', type: 'whatsapp'},
        {icon: 'mdi-send', type: 'telegram'},
        {icon: 'mdi-youtube', type: 'youtube'},
        {icon: 'mdi-instagram', type: 'instagram'},
      ],

      angle: '180',
      color1: '#e7e4f1',
      color2: '#fff'
    }
  },
  computed: {
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  },
  methods: {
    contactWA, contactEmail, socialMediaIG, socialMediaYoutube,
    goto(dest){
      var link
      if (dest === 'telegram') {
        link = socialMediaTelegram()
      } else if(dest === 'instagram') {
        link = socialMediaIG()
      } else if(dest === 'whatsapp') {
        link = contactWA()
      } else if(dest === 'youtube') {
        link = socialMediaYoutube()
      }
      window.open(link, '_blank')
    },
    async getVerification(){
      await this.$root.api.sentAPI(
      {
        method: 'get',
        api: this.$root.api.get.account
      }).then(res => {
        this.verifKtp = res.data.ktpFileName == null ? false : res.data.ktpFileName === 'default.jpg' ? false : true
        this.verifPasFoto = res.data.pasFotoFileName == null ? false : res.data.pasFotoFileName === 'default.jpg' ? false : true
        this.bannerShow = true
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getPeriode(){
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.periode,
      }).then((res) => {
        this.periodes = []
        for (let i = 0; i < res.data.periode.length; i++) {
          var now = moment(new Date()).format("yyyy-MM")
          var periode = moment(res.data.periode[i]).format("yyyy-MM")
          if (moment(periode).isBefore(now) || moment(periode).isSame(now)) {
            this.periodes.push(moment(res.data.periode[i]).format("MMMM-yyyy"))
          } 
        }
        this.bannerShow = true
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    menuDrawerRoleCheck() {
      if(this.role == this.$root.role.admin) this.drawer = true
      else this.drawer = false
    },
    routeTo(link){
      this.$router.push(link)
      this.scrollToTop()
    },
    scrollToTop() {
        window.scrollTo({
          top:0,
          left: 0,
          behavior: 'smooth'
        });
    },
    breakpoint() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          this.drawer = false
          this.showBottomNav = true
          break
          case 'sm': 
          this.drawer = false
          this.showBottomNav = true
          break
          case 'md': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
          case 'lg': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
          case 'xl': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
        }
    },
    onUpdate(value) {
      this.drawer = value
    }
  },
  created() {
    this.breakpoint()
    if(this.isAuthenticated) {
      this.getPeriode()
      this.getVerification()
    }
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  },
  updated() {
    if (!this.bannerShow && this.isAuthenticated) {
      this.getPeriode()
      this.getVerification()
    }
    this.isAuthenticated = store.getters.isAuthenticated
    this.role = store.getters.role
    if(!this.isAuthenticated) {
      this.breakpoint()
      this.bannerShow = false
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 50%;
  z-index: 999;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 12px;
  max-width: 576px;
  min-width: 300px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
