const message = {
    validate : {
        aims                    : 'Mohon masukan nomor aims anda',
        tanzeem                 : 'Mohon masukan badan anda',
        branch                  : 'Mohon masukan cabang anda',
        email                   : 'Mohon masukan email terdaftar',
        otp                     : 'Mohon masukan kode OTP yang sudah dikirimkan'
    },
    result : {
        errMessageInternal      : 'Permintaan gagal, silahkan coba lagi nanti',
        errConnection           : 'Network Error',

        alreadyMemberBufu       : 'Anda sudah terdaftar sebagai anggota koperasi',
        notMemberAims           : 'Data aims tidak ditemukan, silahkan perbarui data anda ke jemaat lokal'
    }
}

export default message