import api from '../../lib/util/api.js'
import message from '../../lib/util/message.js'
import LogRocket from 'logrocket'

const state = {
    user: null,
    role: null,
    token: null,
    isValidateRegister: null,
    isValidateReset: null,
    isValidateConfirmation: null,
    dataUser: {
        name: null,
        tanzeem: null,
        noAims: null,
        branch: null,
        statusMember: null,
        changePass: null
    },
    avatar: false,
    changeEmail: false
}
const getters = {
    isAuthenticated: state => !!state.token,
    accessToken: state => state.token,
    isValidateRegister: state => state.isValidateRegister,
    isValidateReset: state => state.isValidateReset,
    isValidateConfirmation: state => state.isValidateConfirmation,
    stateUser: state => state.user,
    role: state => state.role,
    dataUser: state => state.dataUser,
    getAvatar: state => state.avatar,
    changeEmail: state => state.changeEmail
}
const actions = {
    async LogIn({ commit }, User) {

        return await api.sentAPI({
            method: 'post',
            api: api.post.login,
            form: {
                email: User.get('username'),
                password: User.get('password')
            }
        }).then(res => {
            if (res.data.authenticate.accessToken !== null) {
                commit('setDataUser', {
                    name: res.data.member.fullName,
                    noAims: res.data.member.noAims,
                    statusMember: res.data.member.memberStatus,
                    changePass: res.data.member.changedPassword
                })
                commit('setRole', res.data.member.role)
                commit('setToken', res.data.authenticate.accessToken)
                commit('changeEmail', false)
                commit('setUser', User.get('username'))

                LogRocket.identify(res.data.authenticate.accessToken, {
                    name: res.data.member.fullName,
                    email: res.data.member.email,
                });

                return {
                    status: 200,
                    message: res.data.authenticate.message
                }
            } else {
                return {
                    status: 404,
                    message: res.data.authenticate.message
                }
            }
        }).catch(err => {
            console.log(err)
            return {
                status: 500,
                message: message.result.errMessageInternal
            }
        })
    },

    async Verify({ commit }, User) {
        return await api.sentAPI({
            method: 'get',
            api: api.get.verify + '?branch=' + User.get('branch') + '&noAims=' + User.get('noAims') + '&tanzeem=' + User.get('tanzeem')
        }).then(res => {
            if (Object.keys(res.data).length !== 0) {
                commit('setValidateRegister', true)
                commit('setDataUser', {
                    name: res.data.nama,
                    tanzeem: res.data.nama_badan,
                    noAims: res.data.aims,
                    branch: res.data.nama_cabang,
                    statusMember: res.memberBufu
                })
                return {
                    status: 200,
                    message: res.status.message,
                    member: res.memberBufu
                }
            } else {
                return {
                    status: 404,
                    message: res.status.message,
                    member: res.memberBufu
                }
            }
        }).catch(err => {
            console.log(err)
            return {
                status: 500,
                message: message.result.errMessageInternal
            }
        })
    },

    async VerifyOTP({ commit }, User) {
        return await api.sentAPI({
            method: 'post',
            api: api.post.verifyOTP,
            form: {
                email: User.get('email'),
                otpCode: User.get('otp')
            }
        }).then(res => {
            if (res.status.status === 'Success') {
                commit('setValidateReset', true)
                return {
                    status: res.status.status,
                    token: res.token,
                    message: res.status.message
                }
            } else {
                return {
                    status: res.status.status,
                    token: res.token,
                    message: res.status.message
                }
            }
        }).catch(err => {
            commit('setValidateReset', null)
            console.log(err)
            return {
                status: 500,
                message: message.result.errMessageInternal
            }
        })
    },

    async CreateMember({ commit }, User) {
        return await api.sentAPI({
            method: 'post',
            api: api.post.member,
            form: User
        }).then(res => {
            // console.log(JSON.stringify(res))
            if (res.status.code === 201) {
                commit('setValidateConfirmation', true)
                commit('setValidateRegister', null)
            }
            return {
                data: res.data,
                detail: res.detail,
                code: res.status.code,
                status: res.status.status,
                message: res.status.message
            }
        }).catch(err => {
            console.log(err)
            commit('setValidateConfirmation', true)
            commit('setValidateRegister', null)
            return {
                data: null,
                code: 500,
                status: '',
                message: message.result.errMessageInternal
            }
        })
    },

    async LogOut({ commit }) {
        // await api.sentAPI({
        //     method: 'post',
        //     api: api.post.logout,
        // }).then(() => {
        // }).catch(()=>{})
        commit('LogOut', null)
    },
}
const mutations = {
    setUser(state, username) {
        state.user = username
    },
    setRole(state, role) {
        state.role = role
    },
    LogOut(state) {
        state.role = null
        state.token = null
        state.isValidateRegister = null
        state.dataUser = null
        state.avatar = false
    },
    setToken(state, token) {
        state.token = token
    },
    setValidateRegister(state, validate) {
        state.isValidateRegister = validate
    },
    setValidateReset(state, validate) {
        state.isValidateReset = validate
    },
    setValidateConfirmation(state, validate) {
        state.isValidateConfirmation = validate
    },
    setDataUser(state, data) {
        state.dataUser = data
    },
    setStatusChangePass(state, data) {
        state.dataUser.changePass = data
    },
    getAvatar(state, data) {
        state.avatar = data
    },
    changeEmail(state, data) {
        state.changeEmail = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}