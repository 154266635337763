<template>
    <b-modal 
      id="modal-confirm-saving"
      ref="modal"
      centered>
      <h4 :class="`text-caption font-weight-light mt-4`">Detail dan rincian tagihan sudah dikirim melalui email, silahkan di cek</h4>
        <template #modal-footer>
            <b-button
                variant="primary"
                size="md"
                class="float-right"
                href="https://gmail.com/"
                target="_blank"
                style="color:#fff">Buka Email</b-button>
      </template>
      </b-modal>
</template>
<script>

export default {
    methods: {
        show() {
        this.$refs.modal.show()
        }
    }
}
</script>