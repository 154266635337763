<template>
    <b-nav-item :to="menu.link" v-if="show(menu)"><span>{{menu.title}}</span></b-nav-item>
</template>
<script>
export default {
    name: 'NavMenu',
    props: ['menu'],
    methods: {
      show(menu) {
        if(!menu.show.mobileDisplay) return true
      }
    }
}
</script>

<style scoped>
span{
  color: #343a40
}
</style>