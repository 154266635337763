<template>
    <b-modal 
      id="modal-bill-item"
      ref="modal"
      :title="mode === 'add' ? 'Tambah Nomor Rekening':'Edit Nomor Rekening'"
      @ok="handleOK"
      @show="resetModal"
      @hidden="resetModal"
      centered>
        <v-text-field
                style="margin-bottom:10px"
                label="Nama Rekening"
                :error-messages="accNameError"
                v-model="form.accName"
                required
              >
        </v-text-field>
        <v-text-field
                style="margin-bottom:10px"
                label="Nomor Rekening"
                :error-messages="accNoError"
                v-model="form.accNo"
                required
                type="number"
              >
        </v-text-field>
        <v-select
          v-model="form.selectBank"
          :items="listBank"
          label="Pilih Bank"
          solo
          :error-messages="errorMsg"
          required
          :disabled="mode !== 'add'"
        >
            <template v-slot:selection="{ item }">
                <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                <!-- <span>{{ item.bankName }}</span> -->
            </template>
            <template v-slot:item="{ item }">
                <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                <!-- <span>{{ item.bankName }}</span> -->
            </template>
        </v-select>
        <p v-if="message.billExists !== null" style="color:red">{{ message.billExists }}</p>
        <template #modal-footer>
            <template v-if="!confirmDelete">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="onSubmitModal"
                    style="color:#fff">
                    <span v-show="!isLoad">{{mode === 'add' ? 'TAMBAH' : 'PERBARUI'}}</span>
                    <b-spinner v-show="isLoad" small></b-spinner>
                </b-button>
                <v-btn
                    text
                    style="color:red"
                    v-if="mode !== 'add'"
                    @click="confirmDelete = true"
                >
                    <v-icon>mdi-trash-can-outline</v-icon>
                    HAPUS REKENING
                </v-btn>
            </template>
            <template v-if="confirmDelete">
                <h1 :class="`text-subtitle-1 font-weight-light mr-4`">Yakin ?</h1>
                <v-btn
                    text
                    style="color:green"
                    @click="deleteBill()"
                >
                    Ya
                </v-btn>
                <v-btn
                    text
                    style="color:red"
                    @click="confirmDelete = false"
                >
                    Batal
                </v-btn>
            </template>
      </template>
      </b-modal>
</template>
<script>
import {isEmpty} from '@/lib/util/utilities.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'


export default {
    name: 'BillItemBatch',
    mixins: [validationMixin],
    validations: {
        selectBank: {required},
        accNo: {required},
        accName: {required}
    },
    props: ['mode', 'listBill', 'billData'],
    data() {
        return {
            form: {
                selectBank: '',
                accNo: '',
                accName: '',
                isUsed: ''
            },
            message:{
                billExists: null
            },
            listBank: [],
            isLoad: false,
            confirmDelete: false
        }
    },
    computed: {
        errorMsg() {
        const errors = []
            if (!this.$v.selectBank.$dirty) return errors
            if(this.form.selectBank.length==0) errors.push('Mohon pilih bank dahulu.')
            return errors
        },
        accNoError() {
        const errors = []
            if (!this.$v.accNo.$dirty) return errors
            if(this.form.accNo.length==0) errors.push('Mohon masukan nomor rekening.')
            return errors
        },
        accNameError() {
        const errors = []
            if (!this.$v.accName.$dirty) return errors
            if(this.form.accName.length==0) errors.push('Mohon masukan nama rekening.')
            return errors
        },
    },
    watch:{
        billData: function(newVal) {
          this.form.accName = newVal.accountName
          this.form.accNo = newVal.accountNumber
          this.form.isUsed = newVal.isUsed
          for (let i = 0; i < this.listBank.length; i++) {
              if(this.listBank[i].bankCode === newVal.bankCode){
                this.form.selectBank = this.listBank[i]
              }
          }
        },
        'form.selectBank': function() {
            this.message.billExists = null
        }
    },
    methods: {
        show() {
        this.$refs.modal.show()
        },
        checkModalValidity() {
            this.$v.$touch()
            if(this.mode === 'add'){
                for (let i = 0; i < this.listBill.length; i++) {
                    if(this.listBill[i].bankCode === this.form.selectBank.bankCode){
                        this.message.billExists = 'Rekening ' + this.form.selectBank.bankName + ' sudah terdaftar'
                        break
                    }
                }
            }
            return !isEmpty(this.form.selectBank) && !isEmpty(this.form.accNo) && !isEmpty(this.form.accNo) && this.message.billExists === null
        },
        resetModal() {
            if(this.mode === 'add'){
                this.form.selectBank = ''
                this.form.accNo = ''
                this.form.accName = ''
            }
            this.confirmDelete = false
        },
        handleOK(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.onSubmitModal()
        },
        async onSubmitModal() {
            this.isLoad = true
            if (!this.checkModalValidity()) {
                this.isLoad = false
                return
            }
            await this.$root.api.sentAPI(
            {
                method: 'post',
                api: this.$root.api.post.bill,
                form: {
                    bankCode: this.form.selectBank.bankCode,
                    name: this.form.accName,
                    noRek: this.form.accNo,
                    isPriority: this.form.isUsed
                }
            }).then(() => {
                this.$bvModal.hide('modal-bill-item')
                this.$root.api.sentAPI(
                {
                method: 'get',
                api: this.$root.api.get.bill
                }).then(res => {
                    this.$emit('onUpdate', res.data)
                })
                .catch((err) => {
                    this.$root.isError = true
                    if(!navigator.onLine) this.$root.isError = err.message
                })
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            this.isLoad = false
        },
        deleteBill(){
            this.$root.api.sentAPI(
            {
                method: 'delete',
                api: this.$root.api.post.bill + "/" + this.form.selectBank.bankCode,
            }).then((res) => {
                // console.log(JSON.stringify(res))
                if(res.status.status === 'Success'){
                    this.$bvModal.hide('modal-bill-item')
                    this.$root.api.sentAPI(
                    {
                    method: 'get',
                    api: this.$root.api.get.bill
                    }).then(res => {
                        this.$emit('onUpdate', res.data)
                    })
                    .catch((err) => {
                        this.$root.isError = true
                        if(!navigator.onLine) this.$root.isError = err.message
                    })
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
        }
    },
    mounted(){
        this.$root.api.sentAPI(
        {
            method: 'get',
            api: this.$root.api.get.bank+'?isHasAcc=false',
        }).then((res) => {
            this.listBank = res.data
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
    }
}
</script>