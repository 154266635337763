<template>
    <v-bottom-navigation
        :value="value"
        color="primary"
        fixed
        v-show="showBottomNav"
        class="overflow-y-auto"
        :style="{padding:'0px 0px 0px 0px'}"
      >

      <template v-for="menu in menus" >
          <BottomMenu :menu=menu :key=menu.link :avatar=data.avatar />
      </template>
    </v-bottom-navigation>
</template>
<script>
import BottomMenu from './BottomMenu.vue'
import listmenu from '@/lib/json/list-menu.json'

export default {
    name: 'BottomNavigation',
    props: ['showBottomNav'],
    components: {
        BottomMenu
    },
    data() {
      return {
        value: 0,
        menus: listmenu.data,
        data:{
          avatar: null
        }
      }
    },
    methods:{
      getAvatar(){
        if(this.$store.getters.getAvatar){
          // console.log(this.$store.getters.getAvatar)
          this.$root.api.sentAPI(
          {
            method: 'get',
            api: this.$root.api.get.avatar
          }).then(res => {
              this.data.avatar = res.avatar
          })
          .catch((err) => {
              this.$root.isError = true
              if(!navigator.onLine) this.$root.isError = err.message
          })
        }
      }
    },
    mounted(){
      this.getAvatar()
    },
    updated(){
      this.getAvatar()
    },
    created(){
      for (let i = 0; i < this.menus.length; i++) {
        if(this.$route.path === this.menus[i].link)
          {
            this.value = i
            break
          }
      }
    }
}
</script>