<template>
    <v-navigation-drawer
        v-model="drawer"
        floating
        app
        clipped
        hide-overlay
        stateless
      >
        <v-list
          dense
          rounded
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link"
            :style="{textDecoration:'none', textAlign:'left'}"
          >
            <v-list-item-icon v-if="show(item)">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="show(item)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block color="primary">
              Edit Profil
            </v-btn>
            <v-btn @click="$root.logout" block class="mt-2" color="error">
              Keluar
            </v-btn>
          </div>
        </template>
    </v-navigation-drawer>
</template>
<script>
import listmenu from '@/lib/json/list-menu.json'

export default {
    name: 'NavigationDrawer',
    props: ['drawer'],
    data() {
        return {
            items: listmenu.data
        }
    },
    methods: {
      show(menu) {
        if(!menu.show.mobileDisplay) return true
      }
    },
    updated() {
        this.$emit('clicked', this.drawer)
    },
   
}
</script>