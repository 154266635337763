import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import role from './lib/json/list-role.json'
import { currencyFormat, formatDate, lowercaseFormat, validateEmail, capitalFirstChart, firstChart, setEmptyStringIfNull } from './lib/util/utilities.js'
import message from './lib/util/message.js'
import api from '@/lib/util/api.js'
import terbilang from 'angka-menjadi-terbilang'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import vueCrypt from 'vue-crypt'
import wb from "./registerServiceWorker"
import EasySlider from 'vue-easy-slider'
import LogRocket from 'logrocket'

LogRocket.init('wxshdq/koperasi-bufu');

Vue.prototype.$workbox = wb
Vue.config.productionTip = false

Vue.use(vueCrypt)
Vue.use(EasySlider)
Vue.component(VueCropper)

const app = new Vue({
    data: { overlay: true, isError: false, role: role.data, message, api, isMobile: false, },
    store,
    router,
    vuetify,
    methods: {
        terbilang,
        firstChart,
        capitalFirstChart,
        currencyFormat,
        formatDate,
        lowercaseFormat,
        validateEmail,
        setEmptyStringIfNull,
        async logout() {
            await this.$store.dispatch('LogOut')
            this.$router.push('/home')
        },
        breakpoint() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    this.isMobile = true
                    break
                case 'sm':
                    this.isMobile = true
                    break
            }
        }
    },
    created() {
        if (this.overlay) {
            var self = this;
            window.onbeforeunload = function(e) {
                if (self.$route.path == "/register") {
                    e = e || window.event;
                    //old browsers
                    if (e) { e.returnValue = 'Changes you made may not be saved'; }
                    //safari, chrome(chrome ignores text)
                    return 'Changes you made may not be saved';
                } else {
                    return null;
                }
            };
            if (performance.navigation.type == 1) {
                if (this.$route.path == '/register') {
                    this.$router.push({ path: '/home' })
                } else {
                    // console.log('reload page without redirect');
                }
            }
        }
        this.breakpoint()
    },
    render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    app.overlay = true
    app.isError = false
    next()
})

router.afterEach(() => {
    // setTimeout(() => app.overlay = false, 5000)
})