<template>
    <div>
        <Appbar :drawer = drawer :showBottomNav = showBottomNav @clicked = "isClicked" :role = role />
        <NavigationDrawer :drawer = drawer @clicked = "isClicked" />
        <BottomNavigation :showBottomNav = showBottomNav />
    </div>
</template>
<script>
import Appbar from './navbar/Appbar.vue'
import NavigationDrawer from './navbar/NavigationDrawer.vue'
import BottomNavigation from './navbar/BottomNavigation.vue'

// import store from "../store"

export default {
    props: ['role','drawer','showBottomNav'],
    components: {
        Appbar,
        NavigationDrawer,
        BottomNavigation
    },
    methods: {
        isClicked(drawer) {
            this.$emit('clicked', drawer)
        },  
    },
}
</script>