<template>
  <div :style="{}">
    <!-- e9e5df -->
      <v-main>
        <v-container fluid>
          <!-- <v-row class="justify-content-left pl-2 mt-n5" v-if="this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' ">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  large
                  v-bind="attrs"
                  v-on="on"
                >mdi-settings-helper</v-icon>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title @click="$root.logout">
                    <v-btn
                      text
                      color="red"
                    >
                    <v-icon
                      left
                      dark
                    >
                      mdi-logout
                    </v-icon>
                      Keluar
                    </v-btn>
                    </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row> -->

          <!-- PC design UI -->
          <template v-if="this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl' ">
            <v-container style="width:80%" class="justify-content-center">
                <v-row>
                    <v-col cols="12" md="3" class="mr-4 pa-0">
                        <v-card outlined tile>
                            <v-list class="pa-0">
                                <v-list-group
                                  :value="true"
                                >
                                  <template v-slot:activator>
                                    <v-list-item-content>Akun</v-list-item-content>
                                  </template>
                                  <v-list-item
                                    v-for="([title, id], i) in menu.account"
                                    :key="i"
                                    :class="id == choosenView?'grey lighten-4':''"
                                    @click="chooseEvent(id)"
                                    link
                                  >
                                    <v-list-item-icon class="mr-0">
                                      <v-badge inline v-if="($store.getters.dataUser !== null ? !$store.getters.dataUser.changePass : true) && id === 1.2" dot color="pink">
                                        <v-icon ></v-icon>
                                      </v-badge>
                                      <v-icon v-else ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content v-text="title"></v-list-item-content>
                                  </v-list-item>
                                </v-list-group>
                                <v-divider class="ma-0" />
                                <v-list-item link :class="2 == choosenView?'grey lighten-4':''">
                                    <v-list-item-content @click="chooseEvent(2)">Rekening</v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="8" style="background-color:white">
                        <div v-if="choosenView === 1.1">
                            <v-card flat>
                                <v-card-text>
                                    <Account :accountData=child.accountData.data :block=block @onChange="onChange" @onUpdateAccount="onUpdateAccount" />
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="choosenView === 1.2">
                            <v-card flat>
                                <v-card-text>
                                    <Security @onChange="onChange" :email=child.accountData.data :block=block />
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="choosenView === 1.3">
                            <v-card flat>
                                <v-card-text>
                                    <Verification :accountData=child.accountData.data />
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="choosenView === 2">
                            <v-card flat>
                                <v-card-text>
                                    <Bill :listBill=child.listBill :accountData=child.accountData.data @onChange="onChange" @onUpdateBill="onUpdateBill" />
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
          </template>

          <!-- Mobile design UI -->
          <template v-else>
              <v-row class="justify-content-center pa-5">
                  <v-card style="margin-bottom:100px">
                      <v-row>
                          <v-tabs 
                            dark
                            show-arrows 
                            background-color="grey darken-3"
                            :vertical="vertical">
                              <v-tab>
                                  <v-icon left>
                                      mdi-account
                                  </v-icon>
                                  Akun
                              </v-tab>
                              <v-tab>
                                  <v-icon left>
                                      mdi-book-account
                                  </v-icon>
                                  Rekening
                              </v-tab>
                              <v-divider v-if="vertical" />

                              <v-tab-item class="tab-item overflow-y-auto pa-1">
                                  <v-expansion-panels focusable :style="{width:'925px'}" v-model="panel">
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>Informasi Pribadi</v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <Account :accountData=child.accountData.data :block=block @onChange="onChange" @onUpdateAccount="onUpdateAccount" />
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        Akses akun
                                        <template v-slot:actions v-if="$store.getters.dataUser !== null ? !$store.getters.dataUser.changePass : true">
                                          <v-icon color="warning">
                                            mdi-alert-circle
                                          </v-icon>
                                        </template>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content class="ml-n4 mr-n4">
                                        <Security @onChange="onChange" :email=child.accountData.data :block=block />
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        Verifikasi
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content class="ml-n4 mr-n4">
                                        <Verification :accountData=child.accountData.data />
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                              </v-tab-item>
                              <v-tab-item class="tab-item overflow-y-auto">
                                  <Bill :listBill=child.listBill :accountData=child.accountData.data @onChange="onChange" @onUpdateBill="onUpdateBill" />
                              </v-tab-item>
                          </v-tabs>
                      </v-row>
                  </v-card>
              </v-row>
          </template>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'
import Account from '@/views/profile/Account.vue'
import Bill from '@/views/profile/Bill.vue'
import Security from '@/views/profile/Security.vue'
import Verification from '@/views/profile/Verification.vue'


import store from "../store"

export default {
  name: 'Profile',
  components: {
    OverlayProgress,
    Account,
    Bill,
    Security,
    Verification,
  },
  data() {
    return {
      vertical: false,
      block: false,
      disabled: true,
      accountName: store.getters.dataUser !== null ? store.getters.dataUser.name.split(' ').slice(0,2).join(' ') : '',
      panel: 0,
      choosenView: 1.1,
      menu: {
        account: [
          ['Informasi Pribadi', 1.1],
          ['Akses Akun', 1.2],
          ['Verifikasi', 1.3],
        ],
      },

      child: {
        listBill: [],
        accountData: {
          data: null
        },
        // listBalance: []
      }
    }
  },
  methods: {
    chooseEvent(n){
      this.choosenView = n
    },
    onChange(event){
      this.disabled = !event
    },
    onUpdateBill(value){
      this.child.listBill = value
    },
    onUpdateAccount(value){
      this.child.accountData.data.data = value
    }
  },
  created() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          this.vertical = false
          this.block = true
          break
          case 'sm': 
          this.vertical = false
          this.block = false
          break
          case 'md': 
          this.vertical = true
          this.block = false
          break
          case 'lg': 
          this.vertical = true
          this.block = false
          break
          case 'xl': 
          this.vertical = true
          this.block = false
          break
        }
  },
  async mounted() {
    await this.$root.api.sentAPI(
    {
      method: 'get',
      api: this.$root.api.get.bill
    }).then(res => {
        if(Object.keys(res.data).length !== 0)
          this.child.listBill = res.data
    })
    .catch((err) => {
        this.$root.isError = true
        if(!navigator.onLine) this.$root.isError = err.message
    })

    await this.$root.api.sentAPI(
    {
      method: 'get',
      api: this.$root.api.get.account
    }).then(res => {
        var data = res.data
        // console.log(JSON.stringify(data))
        
        this.$root.api.sentAPI(
          {
            method: 'get',
            api: this.$root.api.get.avatar
          }).then(res => {
              this.child.accountData.data = {
                data,
                avatar: res.avatar
              }
          })
          .catch((err) => {
              this.$root.isError = true
              if(!navigator.onLine) this.$root.isError = err.message
          })
    })
    .catch((err) => {
        this.$root.isError = true
        if(!navigator.onLine) this.$root.isError = err.message
    })

    // await this.$root.api.sentAPI(
    // {
    //   method: 'get',
    //   api: this.$root.api.get.balance
    // }).then(res => {
    //     var response = res.data
    //     // console.log(JSON.stringify(response))
    //     this.child.listBalance = response
    // })
    // .catch((err) => {
    //     this.$root.isError = true
    //     if(!navigator.onLine) this.$root.isError = err.message
    // })

    // if(!this.$root.isError) this.$root.overlay = false
  },
}
</script>
<style>
.v-tabs > .v-tabs-bar .v-tab {
    justify-content: left;
}

.tab-item {
  background-color:#efebf9;
}

</style>
