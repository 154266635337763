<template>
    <div>
    <v-main>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12">
                    <v-card class="mx-auto" width="800" outlined>
                        <v-row>
                            <v-col>
                                <h3 :class="`headline font-weight-light mb-4`">
                                    Tarik Simpanan
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row class="justify-content-center" dense>
                            <v-col md="6" cols="9">
                                <v-alert
                                    colored-border
                                    elevation="1"
                                    >
                                    <v-select
                                        v-model="selectBalance"
                                        :items="listBalance"
                                        placeholder="Tarik dari">

                                        <template v-slot:selection="{ item }">
                                            <span>{{item.simpananName}}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <span>{{item.simpananName}}</span>
                                        </template>

                                    </v-select>
                                    <div style="text-align:left;">
                                        <sup>Rp</sup>
                                        <span class="title">{{$root.currencyFormat(balanceAmount)}}</span>
                                    </div>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-card class="mx-auto pt-5 pb-5" width="800" outlined>
                        <v-row class="justify-content-center" dense>
                            <v-col md="6" cols="9">
                                <v-text-field
                                    label="Nominal Tarik Tunai"
                                    hide-details="auto"
                                    :rules="rulesNominal"
                                    solo
                                    clearable
                                    prefix="Rp."
                                    type="number"
                                    v-model="txtNominal">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="justify-content-center" dense>
                            <v-col md="6" cols="9">
                                <v-select
                                  v-model="selectBill"
                                  :items="listBill"
                                  label="Pilih No Rekening"
                                  solo
                                  :rules="rulesBill"
                                  hide-details="auto"
                                >
                                    <template v-slot:selection="{ item }">
                                        <img :src="require('../assets/bank/'+item.bankLogo)" width="65">
                                        <span>{{ ' - '+item.accountNumber+' ('+item.accountName+')'}}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <img :src="require('../assets/bank/'+item.bankLogo)" width="65">
                                        <span>{{ ' - '+item.accountNumber+' ('+item.accountName+')'}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="errMsg!=null">
                            <v-col dense>
                                <h1 :class="`text-caption font-weight-light`" style="color:red">{{errMsg}}</h1>
                            </v-col>
                        </v-row>
                        <v-row class="justify-content-center">
                            <v-col md="6" cols="9">
                                <v-btn class="primary mr-3" @click="confirm()">Konfirmasi</v-btn>
                                <v-btn class="error" @click="cancel()">Batal</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="justify-content-center mt-3">
                            <router-link style="text-decoration:none" to="/faq/general-questions#withdrawal">
                                <v-btn text color="error" x-small>
                                    Rekening tidak muncul ?
                                </v-btn>
                            </router-link>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                v-model="dialog"
                max-width="500px"
                >
                <v-card>
                    <v-row dense>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-card-title>Rincian Penarikan</v-card-title>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col align="left">
                            <table width="90%" class="ml-5" style="font-size:13px;">
                                <tr>
                                    <td>Nama Rekening</td>
                                    <td style="text-align:right;">{{selectBill!=null ? selectBill.accountName : ''}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Nomor Rekening</td>
                                    <td style="text-align:right;">{{selectBill!=null ? selectBill.accountNumber : ''}}</td>
                                </tr>
                                <tr>
                                    <td>Nominal Penarikan</td>
                                    <td style="text-align:right;">{{$root.currencyFormat(txtNominal)}}</td>
                                </tr>
                                <tr>
                                    <td>Biaya Admin</td>
                                    <td style="text-align:right;">{{selectBalance!=null ? $root.currencyFormat((selectBalance.chargeAmount+addChargeAmountOtherBank())) : ''}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2"><v-divider /></td>
                                </tr>
                                <tr>
                                    <td><b>Total Transfer</b></td>
                                    <td style="text-align:right;"><b>{{$root.currencyFormat(txtNominal)}}</b></td>
                                </tr>
                                <tr>
                                    <td><b>Total Saldo Terpotong</b></td>
                                    <td style="text-align:right;"><b>{{$root.currencyFormat(parseInt(txtNominal)+(selectBalance!=null ? (selectBalance.chargeAmount+addChargeAmountOtherBank()) : 0))}}</b></td>
                                </tr>
                                <br />
                                <tr v-if="((parseInt(this.txtNominal)+(this.selectBalance!=null?(this.selectBalance.chargeAmount+this.addChargeAmountOtherBank()):0)) > (this.selectBalance!=null?this.selectBalance.simpananAmount:0))">
                                    <td><b><span style="color:red">Saldo anda tidak mencukupi</span></b></td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <br />
                    <v-card-text>
                        <v-row>
                            <v-btn
                                color="blue darken-1"
                                text
                                :disabled="((parseInt(this.txtNominal)+(this.selectBalance!=null?(this.selectBalance.chargeAmount+this.addChargeAmountOtherBank()):0)) > (this.selectBalance!=null?this.selectBalance.simpananAmount:0))"
                                :loading="isProcess"
                                @click="postCashWithdrawal"
                            >
                                Kirim Pengajuan
                            </v-btn>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="clear"
                            >
                                Batal
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
                </v-dialog>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              absolute
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
    </v-main>
    </div>

</template>
<script>
import bank from '../lib/json/list-bank.json'

import OverlayProgress from '@/components/OverlayProgress.vue'
export default{
    name: 'Withdrawal',
    components: {
        OverlayProgress
    },
    data () {
        return {
            rulesNominal: [
                value => !!value || 'Mohon masukan nominal yang akan ditarik',
            ],
            rulesBill: [
                value => !!value || 'Mohon pilih rekening tujuan'
            ],
            errMsg: null,
            txtNominal: null,
            selectBill: null,
            selectBalance: null,
            balanceAmount: '0',
            chargeAmount: '0',
            listBill: [],
            listBalance: [],
            snackbar: false,
            timeout: 5000,
            msgSnack: '',
            bodyParam: null,
            isProcess: false,
            dialog: false
        }
    },
    watch: {
        'txtNominal' : function() {
            this.errMsg = null
        },
        'selectBill' : function() {
            this.errMsg = null
        },
        'selectBalance' : function() {
            this.balanceAmount = this.selectBalance.simpananAmount
            this.chargeAmount = this.selectBalance.chargeAmount
        }
    },
    methods: {
        confirm() {
            if(this.txtNominal==null || this.selectBill==null || this.selectBalance==null){
                if(this.txtNominal==null) {
                    this.errMsg = 'Nominal tidak boleh kosong'
                }else if(this.selectBill==null) {
                    this.errMsg = 'Rekening tidak boleh kosong'
                }else if(this.selectBalance==null) {
                    this.errMsg = 'Pilih dahulu simpanan yang akan ditarik'
                }
                return
            }
            if(this.txtNominal < 50000) {
                this.errMsg = 'Penarikan minimal Rp' + this.$root.currencyFormat(50000)
                return
            }
            this.passingBody()
        },
        cancel() {
            this.clear()
        },
        clear() {
            this.txtNominal = null
            this.dialog = false
        },
        convertBill(data) {
            this.listBill = data
            for (let a = 0; a < this.listBill.length; a++) {
                if(this.listBill[a].isUsed==1) {
                    this.selectBill = this.listBill[a]
                }
                for (let b = 0; b < bank.data.length; b++) {
                    if(bank.data[b].bankCode === this.listBill[a].bankCode) {
                    this.listBill[a].bankName = bank.data[b].bankName
                    this.listBill[a].bankLogo = bank.data[b].bankLogo
                    }
                }
            }
        },
        addChargeAmountOtherBank() {
          if(this.selectBill != null) return this.selectBill.chargeAmount
          else return 0
        },
        passingBody() {
            this.dialog = true
            this.bodyParam = {
                amount: this.txtNominal,
                chargeAmount: (this.selectBalance.chargeAmount+this.addChargeAmountOtherBank()),
                simpananId: this.selectBalance.simpananId,
                accountNumber: this.selectBill.accountNumber,
            }
        },
        async getBill() {
            await this.$root.api.sentAPI({
                method: 'get',
                api: this.$root.api.get.bill+'?isFilter=true'
            }).then((result) => {
                this.convertBill(result.data)
            }).catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            }).finally(() => {
                if(!this.$root.isError) this.$root.overlay = false
            })
        },
        async postCashWithdrawal() {
            this.isProcess = true
            await this.$root.api.sentAPI({
                method: 'post',
                api: this.$root.api.post.cashWithdrawal,
                form: this.bodyParam
            }).then((result) => {
                if(result.responseStatus.responseCode==='00') {
                    this.snackbar = true
                    this.msgSnack = 'Pengajuan penarikan sudah dikirimkan, konfirmasi penarikan akan dikirimkan melalui email'
                }else{
                    this.snackbar = true
                    this.msgSnack = result.responseStatus.responseDesc
                }
                this.cancel()
                this.isProcess = false
            }).catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
        },
        async getBalance() {
            await this.$root.api.sentAPI({
                method: 'get',
                api: this.$root.api.get.saldo
            }).then((result) => {
                if(result.status.status === 'Success') {
                    for(var i=0; i<result.data.length; i++) {
                        if(result.data[i]['simpananCode'] === 'STJ-01' && result.data[i]['cashWithdrawal'] === 'T') {
                            this.selectBalance = result.data[i]
                            this.balanceAmount = result.data[i]['simpananAmount']
                            this.chargeAmount = result.data[i]['chargeAmount']
                        }
                        if(result.data[i]['cashWithdrawal'] === 'T') {
                            this.listBalance.push(result.data[i])
                        }
                    }
                }
            }).catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
        }
    },
    async mounted() {
        this.getBill()
        this.getBalance()
    }
}
</script>
