<template>
    <v-btn :to="menu.link" :style="{fontSize:'10px', textDecoration:'none', marginRight:'-7px', marginLeft:'-5px'}">
        <span>{{menu.title}}</span>
        
        <v-badge v-if="($store.getters.dataUser !== null ? !$store.getters.dataUser.changePass : true) && menu.title === 'Profile'" dot color="pink">
            <v-avatar 
                size="25"
                v-if="avatar !== null"
            >
                <img
                alt="Avatar"
                :src="'data:image/jpg;base64,'+avatar"
                >
            </v-avatar>
            <v-icon dense v-else>{{menu.icon}}</v-icon>
        </v-badge>
        <v-avatar 
            size="25"
            v-else-if="avatar !== null && menu.title === 'Profile'"
        >
            <img
            alt="Avatar"
            :src="'data:image/jpg;base64,'+avatar"
            >
        </v-avatar>
        <v-icon dense v-else>{{menu.icon}}</v-icon>
    </v-btn>
</template>
<script>
export default {
    name: 'BottomMenu',
    props: ['menu', 'avatar']
}
</script>