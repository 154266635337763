<template>
    <v-container>
        <v-row class="justify-content-center">
            <v-col cols="12" md="6">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        :disabled="accountData.data.ktpFileName == null ? false : accountData.data.ktpFileName === 'default.jpg' ? false : true" 
                        width="250" 
                        color="primary"
                        @click="$refs.FileInputKtp.click()"
                        v-bind="attrs"
                        v-on="on"
                        ><span v-show="!loadUpload">Verifikasi KTP</span>
                        <b-spinner v-show="loadUpload" small></b-spinner></v-btn>
                    </template>
                    <span>KTP digunakan untuk verifikasi anggota dengan database koperasi</span>
                  </v-tooltip>
            </v-col>
            <v-col cols="12" md="6">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        :disabled="accountData.data.pasFotoFileName == null ? false : accountData.data.pasFotoFileName === 'default.jpg' ? false : true" 
                        width="250" 
                        color="primary" 
                        @click="$refs.FileInputFoto.click()"
                        v-bind="attrs"
                        v-on="on">Verifikasi PAS FOTO</v-btn>
                        </template>
                    <span>PAS FOTO digunakan untuk cetak KTA koperasi</span>
                  </v-tooltip>
            </v-col>
        </v-row>
        <div>
    <div>
        <v-img :src="image_name"></v-img>
        <input ref="FileInputFoto" type="file" style="display: none;" accept="image/*" @change="onFileFotoSelect" />
        <input ref="FileInputKtp" type="file" style="display: none;" accept="image/*" @change="onFileKtpSelect" />
        </div>
        <v-dialog v-model="dialog" width="500">
        <v-card class="pt-5">
            <v-card-text>
                Pastikan foto jelas dan menghadap kedepan, karena akan dipakai untuk ID Card anggota koperasi
            </v-card-text>
            <v-card-actions class="pl-5">
            <v-btn class="primary" @click="saveImage()">
                <span v-show="!loadUpload">Upload</span>
                <b-spinner v-show="loadUpload" small></b-spinner></v-btn>
            <v-btn color="error" text @click="dialog = false">Buang</v-btn>
            </v-card-actions>
            <v-card-text>
            <VueCropper :aspectRatio="1" :autoCropArea="1" :viewMode="1"  v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image"></VueCropper>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
    >
        {{message}}
    </v-snackbar>
    </v-container>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'Verification',
  props: ['accountData'],
  components: {
      VueCropper 
  },
  data() {
    return {
      image_name: '',
      mime_type: '',
      cropedImage: '',
      autoCrop: false,
      selectedFile: '',
      filenameFoto: '',
      image: '',
      dialog: false,
      files: '',
      loadUpload: false,
      snackbar: false,
      timeout: '3000',
      message: ''
    }
  },
  watch:{
      accountData: function(){
          
      }
  },
  created() {
    
  },
  methods: {
    saveImage() {
      this.loadUpload = true
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        blob.lastModifiedDate = new Date()
        blob.name = this.filenameFoto
        this.upload(null, blob)
      }, this.mime_type)
    },
    onFileFotoSelect(e) {
      const file = e.target.files[0]
      this.mime_type = file.type
      this.filenameFoto = file.name
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    onFileKtpSelect(e) {
      this.loadUpload = true
      const file = e.target.files[0]
      this.upload(file, null)
      this.mime_type = file.type
    },
    async upload(ktpFile, pasFotoFile){
        const User = new FormData()
        User.append("ktpFile", ktpFile)
        User.append("pasFotoFile", pasFotoFile)
        await this.$root.api.sentAPI(
        {
          method: 'post',
          api: this.$root.api.post.uploadVerification+'?extensi='+this.mime_type.replace('image/',''),
          form: User
        })
        .then(res => {
          this.message = res.status.message
          if(ktpFile !== null) this.accountData.data.ktpFileName = this.mime_type
          else this.accountData.data.pasFotoFileName = this.mime_type
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
        this.loadUpload = false
        this.dialog = false
        this.snackbar = true
    }
  },
}
</script>
<style scoped>
</style>
